import React from 'react';
import { Popover } from 'reactstrap';
import * as Popper from 'popper.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { faStickyNote as faStickyNoteSolid } from '@fortawesome/free-solid-svg-icons';

const modifiers = {
  //option in popperjs 1.x
  offset: {
    offset: "0, 25",
  },
  preventOverflow: {
    boundariesElement: 'viewport',
  },
};

type ThreadProps = {
  forwardKey?: any;
  isOpen: boolean;
  hasAnnotations: boolean;
  children: any;
  toggle: () => void;
};

export default function Thread(props: ThreadProps) {
  const { forwardKey, isOpen, hasAnnotations, toggle, children } = props;

  return (
    <React.Fragment>
      <span onMouseDown={e => e.stopPropagation()} className="inner-toolbar inner-toolbar-right annotation-row">
        <span id={`popover-thread-${forwardKey}`} title="Thread">
          <FontAwesomeIcon icon={hasAnnotations ? faStickyNoteSolid : faStickyNote} width={16} height={16} />
        </span>
        <Popover
          className="thread"
          fade={false}
          modifiers={modifiers as Popper.Modifiers}
          trigger="click"
          // placement="right-start"
          isOpen={isOpen}
          target={`popover-thread-${forwardKey}`}
          toggle={toggle}
        >
          {
            ({scheduleUpdate}) => {
              scheduleUpdate();
              return children
            }
          }
        </Popover>
      </span>
    </React.Fragment>
  );
}
