import React, { useEffect, useRef, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { EditorState } from 'draft-js';

type BucketProps = {
  current?: ImportedMedia | null;
  children: React.ReactNode;
  currentTimePlaying?: number | null;
  editorState: EditorState;
  isShowButtonClose: boolean;
  onCloseBucket: () => void;
  onBackHistory: () => void;
};
const Bucket = ({
  current,
  children,
  editorState,
  currentTimePlaying,
  isShowButtonClose,
  onCloseBucket,
  onBackHistory,
}: BucketProps) => {
  const mediaRef = useRef<any>(null);
  const router = useRouter();

  useEffect(() => {
    if (currentTimePlaying !== null && mediaRef.current !== null) {
      mediaRef.current.currentTime = currentTimePlaying;
    }
  }, [currentTimePlaying, mediaRef]);

  useEffect(() => {
    if (mediaRef.current == null) return;

    (mediaRef.current as HTMLVideoElement).oncanplay = function(event: Event): void {
      const videoEl = event.target as HTMLVideoElement;
      const vh: number = videoEl.videoHeight || videoEl.height;
      const vw: number = videoEl.videoWidth || videoEl.width;

      if (vh < vw) return;

      videoEl.setAttribute('height', '266');
    };
  }, [current, mediaRef]);

  useEffect(() => {
    // @ts-ignore
    function handleKeydown(e) {
      if (e.keyCode === 32 /* `space` key */) {
        // e.preventDefault();
        if (editorState.getSelection().getHasFocus()) {
          if (mediaRef.current.paused) mediaRef.current.play();
          else mediaRef.current.pause();
        }
      }
    }

    // @ts-ignore
    document.body.addEventListener('keydown', handleKeydown, false);

    return () => {
      // @ts-ignore
      document.body.removeEventListener('keydown', handleKeydown);
    };
  }, [editorState, mediaRef]);

  const renderVideoOrAudio = useMemo(() => {
    if (!current) return null;

    if (current.type === 'audio') {
      return (
        <audio ref={mediaRef} controls key={current.id}>
          <source src={current.url} type="audio/mp3" />
        </audio>
      );
    }

    return (
      <video ref={mediaRef} controls key={current.id}>
        <source src={current.url} type="video/mp4" />
      </video>
    );
  }, [current]);

  return (
    <Container className="bucket-container">
      <Row>
        <Col className="bucket-col">
          <p className="filename">
            <span onClick={() => onBackHistory()} style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faCaretLeft} width={24} height={24} />
            </span>
            <span>{current ? current.name : 'Select a media file'}</span>
            {isShowButtonClose && (
              <span onClick={onCloseBucket} style={{ position: 'absolute', right: 12, top: 0, cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faTimes} width={8} />
              </span>
            )}
          </p>
          <div className="media-container">{renderVideoOrAudio}</div>

          {children}
        </Col>
        {/* <Col id="bucket-col-2" xs="1" m="2" md="3" className="bucket-col-2"></Col> */}
      </Row>
    </Container>
  );
};
export default Bucket;
