// @refresh reset

import React, { useState, useCallback, useReducer, useEffect, Fragment, useContext, useRef, useMemo } from 'react';
import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import Progress from '../Progress';
import {
  GET_ASSEMBLY_PROJECT,
  GET_ASSEMBLY_MEDIA,
  GET_ASSEMBLY_LATEST_VERSION,
  GET_ASSEMBLY_PROJECT_AND_ALL_MEDIA,
  SAVE_ASSEMBLY,
  RENAME_ASSEMBLY_PROJECT,
  ASSEMBLY_UPDATED_SUBSCRIPTION,
  ACTIVE_UPDATE_SUBSCRIPTION,
  SET_BOOKMARKS_PROCESSED,
  GET_EXPORT,
  AMEND_ASSEMBLY_PROJECT,
  ARCHIVE_MEDIA_ASSEMBLY_PROJECT,
  CREATE_MEDIA_FOLDER_IN_PROJECT,
  AMEND_MEDIA,
  AMEND_MEDIA_FOLDER,
  ARCHIVE_MEDIA_FOLDER,
  GET_ACTIVE_VISITORS,
  MARK_AS_ACTIVE_VISITOR,
} from '../../queries';
import { gzip, deflate } from 'pako';
import Navigation from '../Navigation/Navigation';
import Bucket from '../Bucket/Bucket';
import Assembly from '../Assembly/Assembly';
import Sidebar from '../Sidebar/Sidebar';
import { Container, Row, Col, UncontrolledAlert, Button } from 'reactstrap';
import { reorder } from 'react-reorder';
import { createRawContent } from '../../services/Adapter';
import { EditorState, convertFromRaw, SelectionState, ContentBlock, KeyBindingUtil } from 'draft-js';
import Transcript from '../Bucket/Transcript';
const { hasCommandModifier } = KeyBindingUtil;
import {
  clearAllStyles,
  setStylesFromSegments,
  compareSelections,
  mapGenericToSelection,
  getRangeFromSelection,
  mapSelectionToGeneric,
} from '../Bucket/Utils';
import { useQuery, useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { progressBarStore } from '../../context/ProgressBarContext';
import { resolveToNewState } from '../Assembly/Utils';
import { removeApolloTypeName } from '../../common/objectUtils';
import { myDraftStyle } from '../Bucket/Toolbar/plugins';
import ModalConfirmfrom from '../Modal/ModalConfirm';

enum SegmentReducerActionType {
  SET = 'SET',
  SET_PENDING_SAVE = 'SET_PENDING_SAVE',
  INSERT = 'INSERT',
  APPEND = 'APPEND',
  REMOVE = 'REMOVE',
  REMOVE_WITH_ARRAY_INDEX = 'REMOVE_WITH_ARRAY_INDEX',
  REORDER = 'REORDER',
  AMEND = 'AMEND',
  RELOAD = 'RELOAD',
  RENAME_CHAPTER = 'RENAME_CHAPTER',
  REORDER_CHAPTER = 'REORDER_CHAPTER',
  REORDER_WITH_ARRAY_INDEX = 'REORDER_WITH_ARRAY_INDEX',
  INSERT_WITH_ARRAY_INDEX = 'INSERT_WITH_ARRAY_INDEX',
}
import SessionContext from '../../context/SessionContext';

type EditorProps = {
  projectid?: string;
};

type BucketItem = {
  media: ImportedMedia | null;
  transcriptState: EditorState;
  currentTimePlaying: number | null;
  canBeReplaced: boolean;
};

type BucketColName = 'col1' | 'col2';

type BucketProps = {
  col1: BucketItem;
  col2: BucketItem;
};

const Editor = ({ projectid }: EditorProps) => {
  const router = useRouter();
  const { pushProgress, completeProgress, isComplete } = useContext(progressBarStore);
  const { isLoading: isAuthenticating, user } = useAuth0();
  const sortedSegmentsRef = useRef<Segment[] | null>(null);
  const sessionId = useContext(SessionContext);
  const [deferredExport, setDeferredExport] = useState(false);
  const [tempUserId, setTempUserId] = useState<string | null>(null);

  const {
    loading: loadingAssemblyProject,
    data: assemblyProjectData,
    error: errorLoadingAssemblyProject,
    refetch: refetchAssemblyProject,
  } = useQuery<AssemblyProjectData, AssemblyProjectVars>(GET_ASSEMBLY_PROJECT, {
    variables: { projectId: projectid },
    // fetchPolicy: "cache-and-network",
    // pollInterval: 30 * 1000,
    onCompleted: () => {
      completeProgress('project');
    },
  });

  // Pre-caching all the other transcript data.
  const [
    getFullAssemblyData,
    { loading: loadingBackgroundAssemblyMediaData, data: assemblyProjectFullData },
  ] = useLazyQuery<AssemblyProjectData, AssemblyProjectVars>(GET_ASSEMBLY_PROJECT_AND_ALL_MEDIA, {
    variables: { projectId: projectid },
    onCompleted: data => {
      console.log('[Export] Ready for export');
    },
  });

  // Get the latest version of the assembly edit
  const {
    loading: loadingSegments,
    data: segmentsData,
    error: errorLoadingSegments,
    refetch: refetchLatestVersion,
  } = useQuery<AssemblyData, AssemblyProjectVars>(GET_ASSEMBLY_LATEST_VERSION, {
    variables: { projectId: projectid },
    onCompleted: data => {
      completeProgress('segments');
      if (data.getLatestAssembly) {
        if (!unsavedChanges) {
          //only replace editor state if we know we have no unsaved changes.
          console.log(
            '[Assembly] server-side version changes have been received. Resetting segments with latest version',
          );
          let segments = data.getLatestAssembly.segments;
          // TEMPORARY FIX. Add fingerprints to segments on loading if their fingerprint is null. We should be able to remove this at some point.
          // NOTE: because some users may have annotation permission but not save permission, old projects will not be able to be annotated by
          // users with COMMENT privilege until someone with EDIT access opens it
          const fingerPrinting =
            segments.length > 0 &&
            !segments[0].fingerprint &&
            assemblyProjectData?.getAssemblyProject.my_privilege === 'EDIT';
          if (fingerPrinting) {
            console.log('[Assembly] loaded segments without fingerprints. Adding fingerprints now');
            segments = segments.map(segment => {
              console.log('PROCESSING ONE SEGMENT');
              return {
                ...segment,
                fingerprint: segment.fingerprint || uuidv4(),
              };
            });
            console.log(segments);
            handleDispatchSegmentsAndUndoRedo({
              type: SegmentReducerActionType.SET_PENDING_SAVE,
              segments: segments,
            });
          } else {
            handleDispatchSegmentsAndUndoRedo({
              type: SegmentReducerActionType.SET,
              segments: segments,
            });
          }
          setLastVersionLoadedToEditor(data.getLatestAssembly.version);
        } else {
          console.log(
            '[Assembly] did not load latest server version as there are unsaved changes. Will try and merge with server data on next save',
          );
        }
      }
    },
  });

  // Subscribe to updates to the assembly edit
  const { data: subscriptionSegmentsData, loading } = useSubscription<AssemblySubscriptionData, AssemblyProjectVars>(
    ASSEMBLY_UPDATED_SUBSCRIPTION,
    {
      variables: { projectId: projectid },
      onSubscriptionData: data => {
        if (data.subscriptionData.data?.assemblyUpdated) {
          if (!unsavedChanges) {
            //only replace editor state if we know we have no unsaved changes.
            console.log(
              '[Assembly] server-side version changes have been received. Resetting segments with latest version',
            );
            handleDispatchSegmentsAndUndoRedo({
              type: SegmentReducerActionType.SET,
              segments: data.subscriptionData.data.assemblyUpdated.segments,
            });
            setLastVersionLoadedToEditor(data.subscriptionData.data.assemblyUpdated.version);
          } else {
            console.log(
              '[Assembly] did not load latest server version as there are unsaved changes. Will try and merge with server data on next save',
            );
          }
        }
      },
    },
  );

  // Get All the media data
  const [
    getAssemblyMedia,
    { loading: loadingAssemblyMedia, data: assemblyMediaData, error: errorLoadingAssemblyMedia },
  ] = useLazyQuery<AssemblyMediaData, AssemblyMediaVars>(GET_ASSEMBLY_MEDIA, {
    // fetchPolicy: "cache-and-network",
    onCompleted: () => {
      completeProgress('media');
      getFullAssemblyData();
    },
  });

  const [getExport, { loading: loadingExport, data: exportData, error: exportError }] = useLazyQuery(GET_EXPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      completeProgress('exporting');
      if (data.getExport.fileType === 'mp4') {
        //do a popup here
        setDeferredExport(true);
        return;
      } else {
        setDeferredExport(false);
      }
      const text = data.getExport.base64Payload;
      const element = document.createElement('a');
      if (data.getExport.fileType === 'word') {
        element.setAttribute(
          'href',
          'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + text,
        );
      } else {
        element.setAttribute('href', 'data:text/plain;base64,' + encodeURIComponent(text));
      }
      element.setAttribute('download', `${assemblyProjectData?.getAssemblyProject?.name}.${data.getExport.extension}`);
      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    onError: err => {
      completeProgress('exporting');
    },
  });

  // set flag that the bookmarks have been imported as segments for this media
  const [setBookmarksProcessed] = useMutation(SET_BOOKMARKS_PROCESSED);

  // Callback to save assembly. It can fail if a newer version has already been saved.
  // We don't want to automatically replace editor state with the data that was saved, as users may have made further changes since then
  const [saveAssembly, { loading: loadingSaveAssembly, error: errorSaveAssembly }] = useMutation<{
    saveAssembly: AssemblyVersion;
    projectId: number;
    referenceVersion: number;
    segments: Segment[];
  }>(SAVE_ASSEMBLY, {
    onCompleted: data => {
      completeProgress('save');
      setLastVersionLoadedToEditor(data.saveAssembly.version);
      setActionsDuringSave(prev => {
        if (prev.length) {
          setActionsSinceLastSave([...prev]);
        } else {
          setActionsSinceLastSave([]);
          setUnsavedChanges(false);
        }
        return [];
      });
    },
  });

  const [
    amendedAssemblyProject,
    { loading: loadingAmendedAssemblyProject, error: errorAmendedAssemblyProject },
  ] = useMutation<{
    projectId: number;
    width: number;
    height: number;
    fps: string;
    timecode: string;
  }>(AMEND_ASSEMBLY_PROJECT, {
    onCompleted: () => {
      refetchAssemblyProject();
      // completeProgress('rename');
    },
  });

  const [renameProject, { loading: loadingRename, error: errorRename }] = useMutation<{
    renameAssemblyProject: AssemblyProject;
    name: string;
  }>(RENAME_ASSEMBLY_PROJECT, {
    onCompleted: () => {
      completeProgress('rename');
    },
  });

  const [createMediaFolder, { loading: loadingCreateMeidaFolder, error: errorCreateMeidaFolder }] = useMutation<{
    name: string;
    projectId: string;
  }>(CREATE_MEDIA_FOLDER_IN_PROJECT, {
    onCompleted: () => {
      completeProgress('createMediaFolder');
    },
  });

  const [amendMedia, { loading: loadingAmendMedia, error: errorAmendMedia }] = useMutation<{
    mediaId: string;
    folderId: string | null;
  }>(AMEND_MEDIA, {
    onCompleted: () => {
      refetchAssemblyProject();
      completeProgress('amendMedia');
    },
  });

  const [amendMediaFolder, { loading: loadingAmendMediaFolder, error: errorAmendMediaFolder }] = useMutation<{
    folderId: string | null;
    name: string;
  }>(AMEND_MEDIA_FOLDER, {
    onCompleted: () => {
      refetchAssemblyProject();
    },
  });

  const [archiveMediaFolder] = useMutation<{
    folderId: string;
    archive: boolean;
  }>(ARCHIVE_MEDIA_FOLDER, {
    onCompleted: () => {
      refetchAssemblyProject();
    },
  });

  const [archiveMediaInAssemblyProject] = useMutation<{
    projectId: string;
    mediaId: string;
  }>(ARCHIVE_MEDIA_ASSEMBLY_PROJECT, {
    onCompleted: () => {
      refetchAssemblyProject();
    },
  });

  const [getAssemblyVisitors, { loading: loadingActiveVisitors, data: activeVisitorsData }] = useLazyQuery<
    AssemblyVisitorsData,
    AssemblyVisitorsVars
  >(GET_ACTIVE_VISITORS, {
    variables: { projectId: projectid || '', session: sessionId },
    pollInterval: 10 * 1000,
  });

  const [activeOrInactiveVisitor] = useMutation<{
    projectId: string;
    session: string;
    active: boolean;
    activeFingerprint?: string;
  }>(MARK_AS_ACTIVE_VISITOR, {
    onCompleted: () => {},
  });

  const { data: subscriptionActiveUpdate } = useSubscription<ActiveUpdatedSubscriptionData, AssemblyProjectVars>(
    ACTIVE_UPDATE_SUBSCRIPTION,
    {
      variables: { projectId: projectid || '' },
    },
  );

  const segmentReducer: (state: Segment[], action: SegmentReducerAction) => Segment[] = (state, action) => {
    setUnsavedChanges(action.type !== SegmentReducerActionType.SET);
    switch (action.type) {
      case SegmentReducerActionType.SET: {
        return action.segments;
      }

      case SegmentReducerActionType.SET_PENDING_SAVE: {
        return action.segments;
      }

      case SegmentReducerActionType.INSERT: {
        return [...state.slice(0, action.index), action.segment, ...state.slice(action.index)];
      }

      case SegmentReducerActionType.APPEND: {
        if (action.segment) return state.concat(action.segment);
        return state;
      }

      case SegmentReducerActionType.REMOVE: {
        if (typeof action.index === 'number')
          return [...state.slice(0, action.index), ...state.slice(action.index + 1, state.length)];
        return state;
      }

      case SegmentReducerActionType.REMOVE_WITH_ARRAY_INDEX: {
        if (Array.isArray(action.nextIndexes)) {
          const newState: Segment[] = [];
          state.map((seg, idx) => {
            if (!action.nextIndexes?.includes(idx)) {
              newState.push(seg);
            }
          });

          return newState;
        }
        return state;
      }

      case SegmentReducerActionType.INSERT_WITH_ARRAY_INDEX: {
        if (Array.isArray(action.segmentsWithSpecIdx)) {
          const newState: Segment[] = [...state];
          for (const item of action.segmentsWithSpecIdx) {
            newState.splice(item.index, 0, item.segment);
          }

          return newState;
        }
        return state;
      }

      case SegmentReducerActionType.RENAME_CHAPTER: {
        if (typeof action.index === 'number' && action.segment) {
          const newState = [...state];
          newState[action.index] = action.segment;
          return newState;
        }

        return state;
      }

      case SegmentReducerActionType.REORDER_CHAPTER: {
        if (action?.segments?.length) return action.segments;
        return state;
      }

      case SegmentReducerActionType.REORDER_WITH_ARRAY_INDEX: {
        if (
          action?.previousIndexes?.length &&
          action?.nextIndexes?.length &&
          action.previousIndexes.length === action.nextIndexes.length
        ) {
          const newState = [...state];

          for (let idx = 0; idx < action.nextIndexes.length; idx++) {
            let prevIdx = action.previousIndexes[idx];
            let nextIdx = action.nextIndexes[idx];
            const isDownwards = prevIdx < nextIdx;
            let prevSegment = null;

            if (isDownwards) {
              prevIdx -= idx;
              nextIdx = action.nextIndexes[action.isUndo ? idx : 0] - 1;
            }

            prevSegment = newState.splice(prevIdx, 1)[0];
            newState.splice(nextIdx, 0, prevSegment);
          }

          return newState;
        }

        return state;
      }

      case SegmentReducerActionType.REORDER: {
        return reorder(state, action.previousIndex, action.nextIndex);
      }

      case SegmentReducerActionType.AMEND: {
        if (typeof action.index === 'number')
          return [...state.slice(0, action.index), action.segment, ...state.slice(action.index + 1)];
        return state;
      }

      case SegmentReducerActionType.RELOAD: {
        return [...state];
      }

      default: {
        return state;
      }
    }
  };

  const [isExporting, setIsExporting] = useState(false);
  const [errorExporting, setErrorExporting] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(true);
  const [actionsSinceLastSave, setActionsSinceLastSave] = useState<SegmentReducerAction[]>([]);
  const [actionsDuringSave, setActionsDuringSave] = useState<SegmentReducerAction[]>([]);
  const [actionsUndo, setActionsUndo] = useState<SegmentReducerAction[]>([]);
  const [actionsRedo, setActionsRedo] = useState<SegmentReducerAction[]>([]);
  const [lastVersionLoadedToEditor, setLastVersionLoadedToEditor] = useState<number | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  // const [selectedMedia, setSelectedMedia] = useState<ImportedMedia | null>(null);
  // wrap useReducer in useCallback that fix issue sometimes call reducer twice
  const [segments, segmentDispatch] = useReducer(
    useCallback((prevState, action) => segmentReducer(prevState, action), []),
    [],
  );
  const [currentlyPlayingVideo, setCurrentlyPlayingVideo] = useState<number | null>(null);
  const [currentTimePlaying, setCurrentTimePlaying] = useState<number | null>(null);
  //set editor to null to start, as we won't have loaded the transcript at this point anymore
  const widthView = useRef(0);
  const [isOpenModalConfirmDeleteMedia, setIsOpenModalConfirmDeleteMedia] = useState(false);
  const [isOpenModalConfirmDeleteFolder, setIsOpenModalConfirmDeleteFolder] = useState(false);
  const [isOpenModalConfirmDeleteChapter, setIsOpenModalConfirmDeleteChapter] = useState(false);
  const currentRemovedMedia = useRef<ImportedMedia | null>(null);
  const currentRemovedFolder = useRef<ProjectFolder | null>(null);
  const currentRemoveSegmentIndexes = useRef<number[]>([]);
  const currentRemoveMediasInFolder = useRef<ImportedMedia[]>([]);
  const currentRemoveChapter = useRef<number[]>([]);
  const currentClosedBucket = useRef<1 | 2 | null>(null);
  const isInitializedBucket = useRef(false);

  const [bucket, setBucket] = useState<BucketProps>(() => ({
    col1: {
      media: null,
      transcriptState: generateTranscriptState(null),
      currentTimePlaying: null,
      canBeReplaced: false,
    },
    col2: {
      media: null,
      transcriptState: generateTranscriptState(null),
      currentTimePlaying: null,
      canBeReplaced: false,
    },
  }));

  const historyBucket = useRef({
    col1: [],
    col2: [],
  });

  const idle = isComplete();

  const importedMedia = useMemo(() => {
    return assemblyProjectData?.getAssemblyProject?.imported_media?.filter(media => !media.archived);
  }, [assemblyProjectData]);
  const mediaFolders = assemblyProjectData?.getAssemblyProject?.folders.filter(folder => !folder.archived);

  useEffect(() => {
    completeProgress('projectCreate');
    markActiveOrInactiveVisitor(true, '');
    getAssemblyVisitors();
    return () => {
      markActiveOrInactiveVisitor(false, '');
    };
  }, []);
  useEffect(() => {
    if (isAuthenticating) pushProgress('authenticating');
    else {
      // setting this in the future to ensure we don't encounter race condition when isAuthenticating changes from false to true before pushProgress has completed
      setTimeout(() => completeProgress('authenticating'), 100);
    }
  }, [isAuthenticating, user]);
  useEffect(() => {
    if (loadingAssemblyProject) pushProgress('project');
  }, [loadingAssemblyProject]);
  useEffect(() => {
    if (loadingSegments) pushProgress('segments');
  }, [loadingSegments]);
  useEffect(() => {
    if (loadingAssemblyMedia) pushProgress('media');
  }, [loadingAssemblyMedia]);

  useEffect(() => {
    return () => {
      completeProgress('project');
      completeProgress('segments');
      completeProgress('media');
      completeProgress('authenticating');
      completeProgress('rename');
      completeProgress('save');
      completeProgress('exporting');
    };
  }, []);

  useEffect(() => {
    widthView.current = window.innerWidth;

    window.addEventListener('resize', function() {
      widthView.current = window.innerWidth;
    });
  }, []);

  useEffect(() => {
    if (typeof currentlyPlayingVideo === 'number') {
      const activeFingerprint = segments.filter(seg => !seg.is_chapter)[currentlyPlayingVideo].fingerprint;
      markActiveOrInactiveVisitor(true, activeFingerprint);
    }

    if (currentlyPlayingVideo === null) {
      markActiveOrInactiveVisitor(true, '');
    }

    return () => {
      markActiveOrInactiveVisitor(false, '');
    };
  }, [segments, currentlyPlayingVideo]);

  // prompt the user if they try and leave with unsaved changes
  useEffect(() => {
    const warningText = 'You have unsaved changes - are you sure you wish to leave this page?';
    const handleWindowClose = (e: BeforeUnloadEvent) => {
      if (!unsavedChanges) return;
      e.preventDefault();
      markActiveOrInactiveVisitor(false, '');
      return (e.returnValue = warningText);
    };
    const handleBrowseAway = () => {
      if (!unsavedChanges) return;
      if (window.confirm(warningText)) return;
      router.events.emit('routeChangeError');
      throw 'routeChange aborted.';
    };
    window.addEventListener('beforeunload', handleWindowClose);
    router.events.on('routeChangeStart', handleBrowseAway);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
      router.events.off('routeChangeStart', handleBrowseAway);
    };
  }, [unsavedChanges, router.events]);

  // when we have the list of media files, or the selected index changes (but we haven't yet requested the transcript).
  useEffect(() => {
    if (!importedMedia?.length) return;
    if (isInitializedBucket.current) return;

    function getAssemblyMediaTemp(mediaId: string) {
      getAssemblyMedia({ variables: { mediaId } });
    }

    const mediaParams = getMediaParams();

    for (let idx = 0; idx < mediaParams.length; idx++) {
      const curMediaParams = mediaParams[idx];

      // TODO: need to fix empty importedMedia on Dashboard component before get rid of this logic bellow
      // So far, we want to manage column bucket by route BUT we don't have mediaId when open a project on Dashboard

      // let currrentIdx: number = 0;
      // if (curMediaParams) currrentIdx = importedMedia?.findIndex(item => item.id === curMediaParams);
      // const mediaId = importedMedia.length > currrentIdx ? importedMedia[currrentIdx]?.id : null;
      // if(mediaParams[0] === null && mediaId) {
      //   router.push({
      //     pathname: '/[projectid]',
      //     query: {
      //       projectid,
      //       m1: mediaId
      //     }
      //   }, undefined, {shallow: true});
      //   getAssemblyMediaTemp(mediaId);
      //   continue;
      // }

      if (curMediaParams && idx === 0) getAssemblyMediaTemp(curMediaParams);
      else if (curMediaParams && idx === 1) {
        setTimeout(() => {
          // use timeout to bypass the cache of assemblyMediaData
          getAssemblyMediaTemp(curMediaParams);
        }, 1000);
      }
    }

    isInitializedBucket.current = true;
  }, [importedMedia, getAssemblyMedia]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (!importedMedia?.length) return;

      if (currentClosedBucket.current !== null) {
        hideBucketByColName(currentClosedBucket.current);
        currentClosedBucket.current = null;
        return;
      }

      const [media1Id, media2Id] = getMediaParams();
      const media1 = importedMedia?.find(item => item.id === media1Id);
      const media2 = importedMedia?.find(item => item.id === media2Id);

      if (media1 && media1.id !== bucket.col1.media?.id) handleChangeSelected(media1, 1);
      else if (!media1) hideBucketByColName(1);

      if (media2 && media2.id !== bucket.col2.media?.id) handleChangeSelected(media2, 2);
      else if (!media2) hideBucketByColName(2);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [importedMedia, assemblyMediaData, bucket]);

  useEffect(() => {
    setTempUserId(localStorage?.getItem('ss.assemble.anonymous'));
  }, []);

  function handleMediaRoute(media1Id?: string | null, media2Id?: string | null) {
    router.push(
      {
        pathname: '/[projectid]',
        query: {
          projectid,
          ...(media1Id ? { m1: media1Id } : {}),
          ...(media2Id ? { [!media1Id ? 'm1' : 'm2']: media2Id } : {}),
        },
      },
      undefined,
      { shallow: true },
    );
  }

  function getMediaParams(urlParam?: any) {
    if (typeof window === 'undefined') return [null, null];

    const url = urlParam ?? window.location.search;
    const urlParams = new URLSearchParams(url);
    return [urlParams.get('m1'), urlParams.get('m2')];
  }

  function hideBucketByColName(bucketNumber: 1 | 2) {
    const currentCol = ('col' + bucketNumber) as BucketColName;
    setBucket(curBucket => {
      return {
        ...curBucket,
        [currentCol]: {
          ...curBucket[currentCol],
          media: null,
        },
      };
    });
  }

  function prepareEditorState(bucket: BucketItem) {
    const currEditorState = bucket.transcriptState;
    const originalSelection = bucket.transcriptState.getSelection();
    const removedStyle = EditorState.push(currEditorState, clearAllStyles(currEditorState), 'change-inline-style');
    const stylesAdded = setStylesFromSegments(removedStyle, segments, bucket.media!.id);
    const newEditorState = EditorState.acceptSelection(stylesAdded, originalSelection);

    return newEditorState;
  }

  function prepareBucket(
    media: ImportedMedia,
    bucket: BucketProps,
    bucketIdx: number,
    hasChangeStyleEditorState?: boolean | undefined,
    isUseHistory = true,
  ) {
    const curBucket = bucketIdx === 1 ? bucket.col1 : bucket.col2;

    const newBucket = {
      ...curBucket,
      media,
      transcriptState: generateTranscriptState(media.transcript),
      // canBeReplaced: bucketIdx === 1,
    };

    if (hasChangeStyleEditorState) {
      newBucket.transcriptState = prepareEditorState(newBucket);
    }

    if (isUseHistory) {
      historyBucket.current = {
        ...historyBucket.current,
        [`col${bucketIdx}`]: [...(historyBucket.current[`col${bucketIdx}` as BucketColName] || []), newBucket],
      };
    }

    return {
      ...bucket,
      [`col${bucketIdx}`]: {
        ...newBucket,
      },
    };
  }

  // Update transcript editor state once we receive new data for transcript
  useEffect(() => {
    if (assemblyMediaData?.getAssemblyMedia?.transcript) {
      console.log('[Transcript] Got new transcript, generating read-only editor state');

      handleSetBucketByAssemblyMedia();
    }
  }, [assemblyMediaData]);

  useEffect(() => {
    if (assemblyMediaData?.getAssemblyMedia?.transcript) {
      createSegmentsByTranscript(
        assemblyMediaData.getAssemblyMedia,
        assemblyMediaData.getAssemblyMedia.transcript,
        bucket.col1.transcriptState,
      );
    }
  }, [assemblyMediaData, segments, bucket.col1.transcriptState]);

  useEffect(() => {
    if (assemblyMediaData?.getAssemblyMedia?.transcript) {
      createSegmentsByTranscript(
        assemblyMediaData.getAssemblyMedia,
        assemblyMediaData.getAssemblyMedia.transcript,
        bucket.col2.transcriptState,
      );
    }
  }, [assemblyMediaData, segments, bucket.col2.transcriptState]);

  // Rerender styles when the segments change
  useEffect(() => {
    let recalcuateStyle = false;
    const sortedSegments = segments.slice();
    sortedSegments.sort((s1, s2) => {
      if (s1.fingerprint < s2.fingerprint) {
        return -1;
      }
      if (s1.fingerprint > s2.fingerprint) {
        return 1;
      }
      return 0;
    });

    if (!sortedSegmentsRef.current || sortedSegmentsRef.current.length !== segments.length) {
      //segments has changed length
      recalcuateStyle = true;
    } else {
      for (let i = 0; i < sortedSegments.length; i++) {
        if (JSON.stringify(sortedSegments[i]) !== JSON.stringify(sortedSegmentsRef.current[i])) {
          recalcuateStyle = true;
          break;
        }
      }
    }

    sortedSegmentsRef.current = sortedSegments;
    if (!recalcuateStyle) {
      console.log('[Transcript] Skipped re-rendering style as segments only changed in order');
      return;
    }
    console.log('[Transcript] Re-rendering style based on segment change');

    handleSetBucket();
  }, [segments]);

  // Rerender styles when the active transcript selection change
  useEffect(() => {
    console.log('[Transcript] Re-rendering style based on new transcript data');

    handleSetBucket();
  }, [assemblyMediaData, importedMedia]);

  function handleSetBucket() {
    setBucket(curBucket => {
      const newBucket = { ...curBucket };
      const currentMedia = currentRemovedMedia.current;

      if (currentRemovedMedia.current || currentRemoveMediasInFolder.current.length) {
        historyBucket.current = {
          col1: historyBucket.current.col1.filter(
            (bucket: BucketItem) =>
              bucket.media?.id !== currentMedia?.id &&
              !currentRemoveMediasInFolder.current.some(m => m.id === bucket.media?.id),
          ),
          col2: historyBucket.current.col2.filter(
            (bucket: BucketItem) =>
              bucket.media?.id !== currentMedia?.id &&
              !currentRemoveMediasInFolder.current.some(m => m.id === bucket.media?.id),
          ),
        };
      }

      if (newBucket.col1.media !== null) {
        const mediaInfolder = currentRemoveMediasInFolder.current.find(item => item.id === newBucket.col1.media!.id);

        if (
          // close the opened column bucket if its media is removed
          (currentMedia && currentMedia.id === newBucket.col1.media.id) ||
          mediaInfolder
        ) {
          newBucket.col1 = {
            ...newBucket.col1,
            media: null,
          };
        } else {
          newBucket.col1 = {
            ...newBucket.col1,
            transcriptState: newBucket.col1.media
              ? prepareEditorState(newBucket.col1)
              : /* transcriptState is NOT null */ newBucket.col1.transcriptState,
          };
        }
      }

      if (newBucket.col2.media !== null) {
        const mediaInfolder = currentRemoveMediasInFolder.current.find(item => item.id === newBucket.col2.media!.id);

        if (
          // close the opened column bucket if its media is removed
          (currentMedia && currentMedia.id === newBucket.col2.media.id) ||
          mediaInfolder
        ) {
          newBucket.col2 = {
            ...newBucket.col2,
            media: null,
          };
        } else {
          newBucket.col2 = {
            ...newBucket.col2,
            transcriptState: newBucket.col2.media
              ? prepareEditorState(newBucket.col2)
              : /* transcriptState is NOT null */ newBucket.col2.transcriptState,
          };
        }
      }

      return newBucket;
    });
  }

  function createSegmentsByTranscript(media: ImportedMedia, transcript: Transcript[], editorState: EditorState): void {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();
    const currentMediaId = assemblyMediaData?.getAssemblyMedia?.id;
    const bookmarksProcessedFlag = assemblyMediaData?.getAssemblyMedia?.bookmarksProcessed;
    const hasAnySegments = !!segments.filter(seg => seg?.imported_media?.id === currentMediaId).length;
    const userHasWritePermission = assemblyProjectData?.getAssemblyProject.my_privilege === 'EDIT';
    let index = 0;

    if (
      hasAnySegments ||
      blocks.length !== transcript.length ||
      !contentState.getPlainText() ||
      bookmarksProcessedFlag ||
      !userHasWritePermission
    ) {
      return;
    }

    function createSegment(
      media: ImportedMedia,
      blockKey: string,
      anchorOffset: number,
      focusOffset: number,
      start: number | null,
      end: number | null,
      text: string,
    ): void {
      const selection: GenericSelection = mapSelectionToGeneric(
        new SelectionState({
          anchorKey: blockKey,
          focusKey: blockKey,
          anchorOffset,
          focusOffset,
          isBackward: false,
          hasFocus: false,
        }),
        editorState,
      );

      const segmentRange: SegmentRange = {
        start: start ?? 0,
        end: end ?? 0,
        text,
      };

      handleAppendClick(media, segmentRange, selection);
    }

    // length transcriptRow must be equal length blocks
    blocks.map((block: ContentBlock) => {
      const blockKey = block.getKey();
      const transcriptRow = transcript[index];
      const words: Word[] | undefined = transcriptRow.words;
      let start = null; // startTime
      let end = null; // endTime
      let text = '';
      let anchorOffset = null;
      let focusOffset = null;

      if (words) {
        for (const word of words) {
          const hasHightLightStyle = word?.style && word.style.includes(myDraftStyle.HIGHLIGHT);

          if (!hasHightLightStyle) {
            if (focusOffset === null) {
              anchorOffset = (anchorOffset ?? 0) + word.text.length;
            }

            if (focusOffset !== null && anchorOffset !== null) {
              createSegment(media, blockKey, anchorOffset, focusOffset, start, end, text);
              anchorOffset = focusOffset + word.text.length;
              focusOffset = null;
              start = null;
              end = null;
              text = '';
            }
            continue;
          }

          if (hasHightLightStyle) {
            if (anchorOffset === null) anchorOffset = 0;
            focusOffset = (focusOffset ?? anchorOffset) + word.text.length;

            if (start === null) start = word.startTime;
            end = word.endTime;
            text += word.text;
          }
        }

        if (focusOffset !== null && anchorOffset !== null) {
          createSegment(media, blockKey, anchorOffset, focusOffset, start, end, text);
        }
      }
      index++;
    });
    // mark bookmarks as imported
    setBookmarksProcessed({ variables: { mediaId: currentMediaId } });
    return;
  }

  const storePendingAction = (action: SegmentReducerAction) =>
    loadingSaveAssembly ? setActionsDuringSave(a => a.concat(action)) : setActionsSinceLastSave(a => a.concat(action));

  function getDefaultChapter() {
    return {
      imported_media: null,
      is_chapter: true,
      fingerprint: uuidv4(),
      selection: null,
      start: 0,
      end: 0,
      text: 'Untitled Chapter',
    };
  }

  function handleCreateChapter(chapterName: string, index: number | null) {
    const segment = {
      ...getDefaultChapter(),
      text: chapterName,
    };
    const action = { type: SegmentReducerActionType.INSERT, index: index !== null ? index : 0, segment };
    handleDispatchSegmentsAndUndoRedo(action);
    storePendingAction(action);
  }

  function handleCreateBlankSegment(duaration: number = 3, index: number | null) {
    const segment = {
      imported_media: null,
      is_chapter: false,
      fingerprint: uuidv4(),
      selection: null,
      start: 0,
      end: duaration,
      text: 'Untitled Black Video',
    };
    // console.log({action});
    const action = { type: 'INSERT', index: index !== null ? index : 0, segment };
    segmentDispatch(action);
    storePendingAction(action);
  }

  function handleEditBlankSegment(index: number, segment: Segment) {
    const action = { type: 'AMEND', index, segment };
    segmentDispatch(action);
    storePendingAction(action);
  }

  function handleRenameChapter(index: number, segment: Segment) {
    const action = {
      type: SegmentReducerActionType.RENAME_CHAPTER,
      index,
      segment,
      original: segments[index],
      fingerprint: segments[index].fingerprint,
    };

    handleDispatchSegmentsAndUndoRedo(action);
    storePendingAction(action);
  }

  function handleReorderChapter(segments: Segment[]) {
    const action = {
      type: SegmentReducerActionType.REORDER_CHAPTER,
      segments,
      fingerprint: uuidv4(),
    };
    handleDispatchSegmentsAndUndoRedo(action);
    storePendingAction(action);
  }

  function handleInsertClick(media: ImportedMedia, range: SegmentRange, selection: GenericSelection) {
    if (media) {
      const { url, name, id, type, startTimeCode } = media;
      const segment = {
        ...range,
        selection,
        imported_media: { id, url, name, type, startTimeCode },
        fingerprint: uuidv4(),
        is_chapter: false,
      };
      let index = 1;
      if (currentlyPlayingVideo !== null) {
        index = currentlyPlayingVideo + 1;
      }
      const action = { type: SegmentReducerActionType.INSERT, index, segment, fingerprint: segment.fingerprint };
      handleDispatchSegmentsAndUndoRedo(action);
      storePendingAction(action);
    }
  }

  function handleAppendClick(media: ImportedMedia, range: SegmentRange, selection: GenericSelection) {
    if (media) {
      const { url, name, id, type, startTimeCode } = media;
      const segment = {
        ...range,
        selection,
        imported_media: { id, name, url, type, startTimeCode },
        fingerprint: uuidv4(),
        is_chapter: false,
      };
      const action = {
        type: SegmentReducerActionType.APPEND,
        segment,
        index: segments.length,
        fingerprint: segment.fingerprint,
      };
      handleDispatchSegmentsAndUndoRedo(action);
      storePendingAction(action);
    }
  }

  function handleReorderSegment(previousIndex: number, nextIndex: number) {
    // the action history needs a bit more information, so we include segment data here
    const segment = segments[previousIndex];
    const action = {
      type: SegmentReducerActionType.REORDER,
      previousIndex,
      nextIndex,
      segment,
      fingerprint: segment.fingerprint,
    };
    handleDispatchSegmentsAndUndoRedo(action);
    storePendingAction(action);
  }

  // remove item based on range (not index). Used in editor
  function handleRemoveClick(
    media: ImportedMedia,
    transcriptState: EditorState,
    range: SegmentRange,
    selection: GenericSelection,
  ) {
    //find the segment index with this range
    const index = segments.findIndex(
      segment => !segment.is_chapter && compareSelections(segment.selection, selection).identical,
    );
    const segment = segments[index];

    if (index !== -1) {
      const action = { type: SegmentReducerActionType.REMOVE, index, fingerprint: segment.fingerprint };
      handleDispatchSegmentsAndUndoRedo(action);
      storePendingAction(action);
    } else {
      //let's see if we can shorten or split the segment
      const closestIndex = segments.findIndex(
        segment => !segment.is_chapter && compareSelections(segment.selection, selection).subset,
      );
      if (closestIndex !== -1) {
        const editing = segments[closestIndex];
        const comparison = compareSelections(editing.selection, selection);
        if (comparison.sameStart) {
          //trim off start
          const oldSelection = editing.selection;
          const newSelection = {
            ...oldSelection,
            anchorIndex: selection.focusIndex,
            anchorOffset: selection.focusOffset,
          };
          const newRange = getRangeFromSelection(
            transcriptState,
            new SelectionState(mapGenericToSelection(newSelection, transcriptState)),
          );
          const amended = {
            ...editing,
            start: range.end,
            selection: newSelection,
            text: newRange ? newRange.text : '',
          };
          const action = {
            type: SegmentReducerActionType.AMEND,
            index: closestIndex,
            segment: amended,
            original: editing,
            fingerprint: editing.fingerprint,
          };
          handleDispatchSegmentsAndUndoRedo(action);
          storePendingAction(action);
        } else if (comparison.sameEnd) {
          //trim off end
          const oldSelection = editing.selection;
          const newSelection = {
            ...oldSelection,
            focusIndex: selection.anchorIndex,
            focusOffset: selection.anchorOffset,
          };
          const newRange = getRangeFromSelection(
            transcriptState,
            new SelectionState(mapGenericToSelection(newSelection, transcriptState)),
          );
          const amended = {
            ...editing,
            end: range.start,
            selection: newSelection,
            text: newRange ? newRange.text : '',
          };
          const action = {
            type: SegmentReducerActionType.AMEND,
            index: closestIndex,
            segment: amended,
            original: editing,
            fingerprint: editing.fingerprint,
          };
          handleDispatchSegmentsAndUndoRedo(action);
          storePendingAction(action);
        } else {
          //first reduce size of original
          const oldSelection = editing.selection;
          const firstSelection = {
            ...oldSelection,
            focusIndex: selection.anchorIndex,
            focusOffset: selection.anchorOffset,
          };
          const firstRange = getRangeFromSelection(
            transcriptState,
            new SelectionState(mapGenericToSelection(firstSelection, transcriptState)),
          );
          const amended = {
            ...editing,
            end: range.start,
            selection: firstSelection,
            text: firstRange ? firstRange.text : '',
          };
          const action1 = {
            type: SegmentReducerActionType.AMEND,
            index: closestIndex,
            segment: amended,
            original: editing,
            fingerprint: editing.fingerprint,
            isNeedContinueRedo: true,
          };
          handleDispatchSegmentsAndUndoRedo(action1);
          storePendingAction(action1);
          //then add a second one after it
          const secondSelection = {
            ...oldSelection,
            anchorIndex: selection.focusIndex,
            anchorOffset: selection.focusOffset,
          };
          const secondRange = getRangeFromSelection(
            transcriptState,
            new SelectionState(mapGenericToSelection(secondSelection, transcriptState)),
          );
          const newSegment = {
            ...editing,
            start: range.end,
            selection: secondSelection,
            text: secondRange ? secondRange.text : '',
            fingerprint: uuidv4(),
          };
          const action2 = {
            type: SegmentReducerActionType.INSERT,
            index: closestIndex + 1,
            segment: newSegment,
            fingerprint: editing.fingerprint,
            isNeedContinueUndo: true,
          };
          handleDispatchSegmentsAndUndoRedo(action2);
          storePendingAction(action2);
        }
      } else {
        handleDispatchSegmentsAndUndoRedo({ type: SegmentReducerActionType.RELOAD });
      }
    }
  }

  function handleRemoveSegment(index: number) {
    const segment = segments[index];
    const action = { type: SegmentReducerActionType.REMOVE, index, fingerprint: segment.fingerprint };
    handleDispatchSegmentsAndUndoRedo(action);
    storePendingAction(action);
  }

  function handleRemoveSegments(nextIndexes: number[], isUseUndoRedo: boolean = true) {
    const segmentsWithSpecIdx: { index: number; segment: Segment }[] = [];

    if (isUseUndoRedo) {
      for (const index of nextIndexes) {
        segmentsWithSpecIdx.push({
          index,
          segment: segments[index],
        });
      }
    }

    const action = {
      type: SegmentReducerActionType.REMOVE_WITH_ARRAY_INDEX,
      nextIndexes,
      segmentsWithSpecIdx,
      fingerprint: uuidv4(),
    };

    if (isUseUndoRedo) handleDispatchSegmentsAndUndoRedo(action);
    else segmentDispatch(action);
    storePendingAction(action);
  }

  function handleReorderSegments(previousIndexes: number[], nextIndexes: number[]) {
    const action = {
      type: SegmentReducerActionType.REORDER_WITH_ARRAY_INDEX,
      previousIndexes,
      nextIndexes,
      fingerprint: uuidv4(),
    };

    handleDispatchSegmentsAndUndoRedo(action);
    storePendingAction(action);
  }

  function handleAddRevertAction(action: SegmentReducerAction, identifier: string) {
    const actionUndo: SegmentReducerAction = { ...action };
    const setActionsToStack = identifier === 'undo' ? setActionsUndo : setActionsRedo;

    switch (action.type) {
      case SegmentReducerActionType.INSERT: {
        actionUndo.prevActionType = SegmentReducerActionType.INSERT;
        actionUndo.type = SegmentReducerActionType.REMOVE;

        setActionsToStack(prev => prev.concat(actionUndo));
        return;
      }

      case SegmentReducerActionType.APPEND: {
        actionUndo.prevActionType = SegmentReducerActionType.APPEND;
        actionUndo.type = SegmentReducerActionType.REMOVE;

        setActionsToStack(prev => prev.concat(actionUndo));
        return;
      }

      case SegmentReducerActionType.REMOVE: {
        actionUndo.prevActionType = SegmentReducerActionType.REMOVE;
        actionUndo.type = SegmentReducerActionType.INSERT;
        actionUndo.segment = segments[actionUndo.index!];

        setActionsToStack(prev => prev.concat(actionUndo));
        return;
      }

      case SegmentReducerActionType.REORDER: {
        actionUndo.prevActionType = SegmentReducerActionType.REORDER;
        actionUndo.type = SegmentReducerActionType.REORDER;
        actionUndo.previousIndex = action.nextIndex;
        actionUndo.nextIndex = action.previousIndex;

        setActionsToStack(prev => prev.concat(actionUndo));
        return;
      }

      case SegmentReducerActionType.AMEND: {
        actionUndo.prevActionType = SegmentReducerActionType.AMEND;
        actionUndo.type = SegmentReducerActionType.AMEND;
        actionUndo.original = action.segment;
        actionUndo.segment = action.original;

        setActionsToStack(prev => prev.concat(actionUndo));
        return;
      }

      case SegmentReducerActionType.RENAME_CHAPTER: {
        actionUndo.prevActionType = SegmentReducerActionType.RENAME_CHAPTER;
        actionUndo.type = SegmentReducerActionType.RENAME_CHAPTER;
        actionUndo.original = action.segment;
        actionUndo.segment = action.original;

        setActionsToStack(prev => prev.concat(actionUndo));
        return;
      }

      case SegmentReducerActionType.REORDER_CHAPTER: {
        actionUndo.prevActionType = SegmentReducerActionType.REORDER_CHAPTER;
        actionUndo.type = SegmentReducerActionType.REORDER_CHAPTER;
        actionUndo.segments = segments;

        setActionsToStack(prev => prev.concat(actionUndo));
        return;
      }

      case SegmentReducerActionType.REMOVE_WITH_ARRAY_INDEX: {
        actionUndo.prevActionType = SegmentReducerActionType.REMOVE_WITH_ARRAY_INDEX;
        actionUndo.type = SegmentReducerActionType.INSERT_WITH_ARRAY_INDEX;
        actionUndo.nextIndexes = action.nextIndexes;

        setActionsToStack(prev => prev.concat(actionUndo));
        return;
      }

      case SegmentReducerActionType.INSERT_WITH_ARRAY_INDEX: {
        actionUndo.prevActionType = SegmentReducerActionType.INSERT_WITH_ARRAY_INDEX;
        actionUndo.type = SegmentReducerActionType.REMOVE_WITH_ARRAY_INDEX;
        actionUndo.nextIndexes = action.nextIndexes;

        setActionsToStack(prev => prev.concat(actionUndo));
        return;
      }

      case SegmentReducerActionType.REORDER_WITH_ARRAY_INDEX: {
        actionUndo.prevActionType = SegmentReducerActionType.REORDER_WITH_ARRAY_INDEX;
        actionUndo.type = SegmentReducerActionType.REORDER_WITH_ARRAY_INDEX;

        actionUndo.nextIndexes = action.previousIndexes!.map((prevItem, index) => {
          const nextItem = action.nextIndexes![index];
          const length = action.nextIndexes!.length;
          const isDownwards = prevItem < nextItem;

          return prevItem + (!isDownwards ? 1 * length : 0);
        });

        actionUndo.previousIndexes = action.nextIndexes!.map((nextItem, index) => {
          const prevItem = action.previousIndexes![index];
          const length = action.nextIndexes!.length;
          const isDownwards = prevItem < nextItem;

          return nextItem + (isDownwards ? -1 * length : 0);
        });

        setActionsToStack(prev => prev.concat(actionUndo));
        return;
      }

      default: {
        return;
      }
    }
  }

  function handleActionUndo(actionsUndo: SegmentReducerAction[]) {
    const pendingStack = loadingSaveAssembly ? actionsDuringSave : actionsSinceLastSave;
    const setPendingStack = loadingSaveAssembly ? setActionsDuringSave : setActionsSinceLastSave;
    const newUndoActions = [...actionsUndo];

    // console.log("[UNDO_STACK]: BEFORE pop item to undo stack", { actionsUndo, actionsRedo, pendingStack });
    if (newUndoActions.length) {
      const lastUndoAction = newUndoActions.pop();

      if (lastUndoAction) {
        const newPendingActions = pendingStack.filter(
          action =>
            lastUndoAction.prevActionType !== action.type && lastUndoAction?.fingerprint !== action?.fingerprint,
        );

        setActionsUndo(newUndoActions);
        setPendingStack(newPendingActions);
        handleDispatchSegmentsAndUndoRedo({ ...lastUndoAction, isUndo: true, isRedo: false });

        // console.log("[UNDO_STACK]: AFTER pop item to undo stack", { newUndoActions, newPendingActions });
        if (lastUndoAction.isNeedContinueUndo === true) {
          handleActionUndo(newUndoActions);
        }
      }
    }
  }

  function handleActionRedo(actionsRedo: SegmentReducerAction[]) {
    const pendingStack = loadingSaveAssembly ? actionsDuringSave : actionsSinceLastSave;
    const setPendingStack = loadingSaveAssembly ? setActionsDuringSave : setActionsSinceLastSave;
    const newRedoActions = [...actionsRedo];

    // console.log("[REDO_STACK]: BEFORE pop item to redo stack", { actionsUndo, actionsRedo, pendingStack });
    if (newRedoActions.length) {
      const lastRedoAction = newRedoActions.pop();

      if (lastRedoAction) {
        const newPendingActions = pendingStack.concat(lastRedoAction);

        setActionsRedo(newRedoActions);
        setPendingStack(newPendingActions);
        handleDispatchSegmentsAndUndoRedo({ ...lastRedoAction, isRedo: true, isUndo: false });

        // console.log("[REDO_STACK]: AFTER pop item to redo stack", { newRedoActions, newPendingActions });
        if (lastRedoAction.isNeedContinueRedo === true) {
          handleActionRedo(newRedoActions);
        }
      }
    }
  }

  function handleDispatchSegmentsAndUndoRedo(action: SegmentReducerAction) {
    if (typeof action.isUndo === 'undefined' && typeof action.isRedo === 'undefined') setActionsRedo([]);

    if (typeof action.isUndo === 'undefined' || action.isRedo === true) {
      // console.log("[UNDO_STACK]: push item to undo stack", { action });
      handleAddRevertAction(action, 'undo');
    }

    if (action.isUndo === true) {
      // console.log("[REDO_STACK]: push item to redo stack", { action });
      handleAddRevertAction(action, 'redo');
    }

    segmentDispatch(action);
  }

  function updateRoute(media: ImportedMedia | null) {
    if (!importedMedia || !projectid || !media) return;
    const [media1Id, media2Id] = getMediaParams();
    const mediaId = media.id;

    if (!media1Id && !media2Id) {
      handleMediaRoute(mediaId);
    } else if ((!media2Id || !media2Id) && mediaId) {
      handleMediaRoute(media1Id || media2Id, mediaId);
    } else if (bucket.col1.canBeReplaced) {
      handleMediaRoute(mediaId, bucket.col2.media?.id);
    } else if (bucket.col2.canBeReplaced) {
      handleMediaRoute(bucket.col1.media?.id, mediaId);
    }
  }

  function handleSetBucketByAssemblyMedia(forceCol?: 1 | 2) {
    if (!assemblyMediaData) return;

    setBucket(curBucket => {
      const [media1Id, media2Id] = getMediaParams();
      const shouldUseCol1 = !!(curBucket.col1.media && media1Id && curBucket.col1.canBeReplaced);
      const shouldUseCol2 = !!(curBucket.col2.media && media2Id && curBucket.col2.canBeReplaced);

      if (assemblyMediaData.getAssemblyMedia.archived) {
        return curBucket;
      }
      if (isNarrowView()) {
        return prepareBucket(assemblyMediaData.getAssemblyMedia, curBucket, 1);
      } else if (forceCol) {
        return prepareBucket(assemblyMediaData!.getAssemblyMedia, curBucket, forceCol, true, false);
      } else if (curBucket.col1.media === null) {
        //|| assemblyMediaData.getAssemblyMedia.id === curBucket.col1.media.id
        return prepareBucket(assemblyMediaData.getAssemblyMedia, curBucket, 1);
      } else if (curBucket.col2.media === null) {
        return prepareBucket(assemblyMediaData.getAssemblyMedia, curBucket, 2);
      } else if (shouldUseCol1) {
        return prepareBucket(assemblyMediaData.getAssemblyMedia, curBucket, 1);
      } else if (shouldUseCol2) {
        return prepareBucket(assemblyMediaData.getAssemblyMedia, curBucket, 2);
      }
      return curBucket;
    });
  }

  function handleChangeSelected(media: ImportedMedia | null, forceCol?: 1 | 2) {
    function getAssemblyMediaTemp(mediaId: string) {
      getAssemblyMedia({ variables: { mediaId } });
    }

    const isOpenIdenticalMedia = assemblyMediaData?.getAssemblyMedia.id === media?.id;

    if (isOpenIdenticalMedia) handleSetBucketByAssemblyMedia(forceCol);
    if (!isOpenIdenticalMedia && media) getAssemblyMediaTemp(media.id);
  }

  const exportAssembly: (fileType: string, extension: string) => any = async (fileType, extension) => {
    if (!importedMedia) {
      throw Error('No media to export');
    }

    pushProgress('exporting');
    getExport({
      variables: {
        projectId: projectid,
        fileType,
        extension,
      },
    });
  };

  const saveCurrentSegments = useCallback(async () => {
    if (!segmentsData) {
      return;
    }

    try {
      pushProgress('save');
      await saveAssembly({
        variables: {
          referenceVersion: lastVersionLoadedToEditor,
          projectId: projectid,
          segments: segments.map((segment, sort_index) => {
            const { id, imported_media, annotations, reactions, is_chapter, ...segmentData } = removeApolloTypeName(
              segment,
            );
            segmentData.selection = removeApolloTypeName(segment.selection);

            return {
              ...segmentData,
              sort_index,
              is_chapter,
              imported_media_id: (!is_chapter && imported_media?.id) || null,
              ...(is_chapter ? { start: null, end: null } : {}),
            };
          }),
        },
      });

      if (currentRemovedMedia.current !== null) {
        await archiveMediaInAssemblyProject({
          variables: {
            projectId: projectid,
            mediaId: currentRemovedMedia.current.id,
          },
        });
        currentRemovedMedia.current = null;
      }

      if (currentRemoveSegmentIndexes.current.length && currentRemovedFolder.current !== null) {
        archiveMediaFolder({
          variables: {
            folderId: currentRemovedFolder.current.id,
            archive: true,
          },
        });
        resetStateRemoveFolder();
      }
    } catch (err) {
      //we can fail here if there is a newer version serverside
      console.log('[Assembly] caught a save error. Further checks required');
      if (err.toString().includes('Assembly version newer than')) {
        console.log(`[Assembly] tried to save but newer version exists on server: ${err.toString()}`);
        // pause saving
        setSaveEnabled(false);
        setUnsavedChanges(true);
        // move actions "during save" to "since last save"
        setActionsDuringSave(during => {
          setActionsSinceLastSave(prev => [...prev, ...during]);
          return [];
        });
        // fetch latest data, and perform conflict resolution on its callback
        const refetchServerVersion = await refetchLatestVersion();
        // perform conflict resolution. Use the setter to ensure we get the latest version of the actions since last save
        setActionsSinceLastSave(prev => {
          const resolvedSegments = resolveToNewState(refetchServerVersion.data.getLatestAssembly.segments, prev);
          handleDispatchSegmentsAndUndoRedo({
            type: SegmentReducerActionType.SET_PENDING_SAVE,
            segments: resolvedSegments,
          });
          return prev;
        });
        setLastVersionLoadedToEditor(refetchServerVersion.data.getLatestAssembly.version);
        setSaveEnabled(true);
      } else throw err; //resume standard error chain
    }
  }, [
    segments,
    projectid,
    saveAssembly,
    segmentsData,
    actionsSinceLastSave,
    refetchLatestVersion,
    lastVersionLoadedToEditor,
    setLastVersionLoadedToEditor,
  ]);

  const autoSave = useCallback(() => {
    if (unsavedChanges && idle) {
      saveCurrentSegments();
    }
  }, [unsavedChanges, idle, saveCurrentSegments]);

  useEffect(() => {
    // @ts-ignore
    function handleKeydown(e) {
      if (e.keyCode === 83 /* `s` key */ && hasCommandModifier(e)) {
        e.preventDefault();
        if (segments) saveCurrentSegments();
      }

      if (e.keyCode === 90 /* `z` key */ && hasCommandModifier(e) && !e.shiftKey) {
        e.preventDefault();
        if (segments) handleActionUndo(actionsUndo);
      }

      if (e.keyCode === 90 /* `z` key */ && hasCommandModifier(e) && e.shiftKey) {
        e.preventDefault();
        if (segments) handleActionRedo(actionsRedo);
      }
    }

    // @ts-ignore
    document.body.addEventListener('keydown', handleKeydown, false);

    return () => {
      // @ts-ignore
      document.body.removeEventListener('keydown', handleKeydown);
    };
  }, [segments, saveCurrentSegments, actionsUndo, actionsRedo, loadingSaveAssembly]);

  // save after 2s of no changes to segments
  useEffect(() => {
    if (saveEnabled) {
      const saveInterval = setInterval(
        autoSave,
        process.env.NEXT_PUBLIC_AUTO_SAVE_INTERVAL ? Number(process.env.NEXT_PUBLIC_AUTO_SAVE_INTERVAL) * 1000 : 2000,
      );
      return () => {
        clearInterval(saveInterval);
      };
    }
  }, [autoSave, saveEnabled]);

  function generateTranscriptState(transcript?: Transcript[] | null) {
    const rawContent = createRawContent(transcript);
    const contentState = convertFromRaw(rawContent);
    return EditorState.createWithContent(contentState);
  }

  function onCloseBucket(numBucket: 1 | 2) {
    currentClosedBucket.current = numBucket;

    if (numBucket === 1) handleMediaRoute(undefined, bucket.col2.media?.id);
    if (numBucket === 2) handleMediaRoute(bucket.col1.media?.id, undefined);
  }

  function handleBackHistoryBucket(numBucket: 1 | 2) {
    const curBucket = numBucket === 1 ? bucket.col1 : bucket.col2;
    const curHistory = historyBucket.current[`col${numBucket}` as BucketColName];
    const length = curHistory.length;
    const newHistory = curHistory.slice(0, length - 1);
    historyBucket.current[`col${numBucket}` as BucketColName] = newHistory;
    const newBucket = newHistory[newHistory.length - 1];

    if (!newBucket) onCloseBucket(numBucket);

    setBucket(bucket => ({
      ...bucket,
      [`col${numBucket}`]: newBucket ?? {
        ...curBucket,
        media: null,
      },
    }));
  }

  function isNarrowView() {
    return widthView.current < 1080;
  }

  function handleRemoveMedia(media: ImportedMedia) {
    currentRemovedMedia.current = media;
    const hasAnyMeidasInSegments = segments.some((segment: Segment) => segment?.imported_media?.id === media.id);

    if (hasAnyMeidasInSegments && currentRemovedMedia.current !== null) {
      setIsOpenModalConfirmDeleteMedia(!isOpenModalConfirmDeleteMedia);
    } else {
      archiveMediaInAssemblyProject({ variables: { projectId: projectid, mediaId: media.id } });
    }
  }

  function deleteAllSegmetsAndMeida() {
    if (!currentRemovedMedia.current) return;

    const mediaId = currentRemovedMedia.current.id;
    let count = 0;
    segments.map((segment: Segment, index: number) => {
      if (segment?.imported_media?.id === mediaId) {
        handleRemoveSegment(index - count);
        count++;
      }
    });

    setIsOpenModalConfirmDeleteMedia(!isOpenModalConfirmDeleteMedia);
  }

  function handleRemoveChapter(indexes: number[]) {
    currentRemoveChapter.current = indexes;
    setIsOpenModalConfirmDeleteChapter(prev => !prev);
  }

  function handleRemoveOnlyChapter() {
    handleRemoveSegment(currentRemoveChapter.current[0]);
    setIsOpenModalConfirmDeleteChapter(prev => !prev);
  }

  function handleRemoveChapterAndSelections() {
    handleRemoveSegments(currentRemoveChapter.current);
    setIsOpenModalConfirmDeleteChapter(prev => !prev);
  }

  function getHasBucketFocused() {
    return (
      bucket.col1.transcriptState.getSelection().getHasFocus() ||
      bucket.col2.transcriptState.getSelection().getHasFocus()
    );
  }

  function handleAmendAssemblyProject(mediaInfo: { height?: number; width?: number; fps?: string; timecode?: string }) {
    amendedAssemblyProject({ variables: { projectId: projectid, ...mediaInfo } });
  }

  function handleCreateMediaFolder(folderName: string, clearTextMediaFolder: Function) {
    const trimedFolderName = folderName.trim();
    if (trimedFolderName) {
      createMediaFolder({
        variables: {
          name: trimedFolderName,
          projectId: projectid,
        },
      });
    }

    clearTextMediaFolder();
  }

  function handleAmendMedia(mediaId: string, folderId: string | null) {
    amendMedia({
      variables: {
        mediaId,
        folderId,
      },
    });
  }

  function handleAmendMediaFolder(folderId: string, folderName: string) {
    const trimedFolderName = folderName.trim();
    if (trimedFolderName) {
      amendMediaFolder({
        variables: { folderId, name: trimedFolderName },
      });
    }
  }

  function handleArchiveMediaFolder(folder: ProjectFolder) {
    function archiveFolder() {
      archiveMediaFolder({
        variables: {
          folderId: folder.id,
          archive: true,
        },
      });
    }

    currentRemovedFolder.current = folder;
    const meidasUnArchivedInFolder = folder.imported_media.filter((media: ImportedMedia) => !media.archived);
    currentRemoveMediasInFolder.current = meidasUnArchivedInFolder;

    if (meidasUnArchivedInFolder.length) {
      for (const media of meidasUnArchivedInFolder) {
        const hasAnyMeidasInSegments = segments.some((segment: Segment) => segment?.imported_media?.id === media.id);

        if (hasAnyMeidasInSegments) {
          segments.map((segment: Segment, index: number) => {
            if (segment?.imported_media?.id === media.id) {
              currentRemoveSegmentIndexes.current.push(index);
            }
          });
        }
      }

      if (currentRemoveSegmentIndexes.current.length) {
        setIsOpenModalConfirmDeleteFolder(!isOpenModalConfirmDeleteFolder);
      } else {
        archiveFolder();
      }
    } else {
      archiveFolder();
    }
  }

  function deleteAllSegmetsAndMeidaInFolder() {
    handleRemoveSegments(currentRemoveSegmentIndexes.current, false);
    setIsOpenModalConfirmDeleteFolder(!isOpenModalConfirmDeleteFolder);
  }

  function resetStateRemoveFolder() {
    currentRemovedFolder.current = null;
    currentRemoveSegmentIndexes.current = [];
    currentRemoveMediasInFolder.current = [];
  }

  function markActiveOrInactiveVisitor(active: boolean, activeFingerprint?: string) {
    activeOrInactiveVisitor({
      variables: {
        projectId: projectid,
        session: sessionId,
        active,
        activeFingerprint,
      },
    });
  }

  let errorMessage = '';
  let successMesssage = '';
  if (errorLoadingSegments) {
    if (errorLoadingSegments.message == 'Invalid arguments') {
      errorMessage = "Couldn't find this project 🥺. Check the url and try again!";
    } else if (errorLoadingSegments.message.includes('Destination project does not exist for this user')) {
      errorMessage = "Looks like you don't have permission to access this project 🥺. Let the project owner know!";
    } else {
      errorMessage = "😓 Uh oh. Couldn't grab your chosen segments. Check your internet settings and try reloading";
    }
  }
  if (errorLoadingAssemblyMedia) {
    errorMessage = "😓 Uh oh. Couldn't grab the transcript. Check your internet settings and try reloading";
  }
  if (errorLoadingAssemblyMedia) {
    errorMessage = "😓 Uh oh. Couldn't load your projects. Check your internet settings and try reloading";
  }
  if (errorSaveAssembly) {
    if (!errorSaveAssembly.message.includes('Assembly version newer than')) {
      errorMessage = "😓 Uh oh. Couldn't save your project. Check your internet settings and click 'Save Now'";
    }
  }
  if (exportError) {
    errorMessage = "😓 Uh oh. Failed to export. Try again, or if that doesn't work, please contact support";
  }
  if (deferredExport) {
    successMesssage = "🙌 We'll send you an email with a link to your exported file in a few minutes";
  }
  if (isAuthenticating) {
    errorMessage = '';
  }

  const mediaInfo = {
    height: assemblyProjectData?.getAssemblyProject?.height,
    width: assemblyProjectData?.getAssemblyProject?.width,
    timecode: assemblyProjectData?.getAssemblyProject?.timecode,
    fps: assemblyProjectData?.getAssemblyProject?.fps,
  };
  const userHasWritePermission = assemblyProjectData?.getAssemblyProject.my_privilege === 'EDIT';

  function visitorFilter(user: any, temp_user_id: string | null, visitors?: Visitor[], alwaysExcludeSelf?: boolean) {
    if (!visitors) return [];

    const otherVisitors =
      visitors?.filter(visitor => visitor.email !== user?.email && visitor.temp_user_id !== temp_user_id) || [];

    if (!otherVisitors.length) return [];
    if (alwaysExcludeSelf) return otherVisitors;
    return visitors;
  }

  const otherVisitorsFilter = useMemo(() => {
    const visitors = activeVisitorsData?.getActiveVisitors;
    return visitorFilter(user, tempUserId, visitors);
  }, [activeVisitorsData?.getActiveVisitors]);

  const otherVisitorsFilterExcludeSelf = useMemo(() => {
    const visitors = activeVisitorsData?.getActiveVisitors;
    return visitorFilter(user, tempUserId, visitors, true);
  }, [activeVisitorsData?.getActiveVisitors]);

  return (
    <Fragment>
      <Progress />
      {errorMessage && <UncontrolledAlert color="danger">{errorMessage}</UncontrolledAlert>}
      {successMesssage && <UncontrolledAlert color="success">{successMesssage}</UncontrolledAlert>}
      <div className="wrapper">
        <Sidebar
          openModalConfirmDeleteMedia={handleRemoveMedia}
          loaded={importedMedia}
          mediaFolders={mediaFolders}
          projectId={projectid}
          handleChangeSelected={updateRoute}
          handleCreateMediaFolder={handleCreateMediaFolder}
          handleAmendMedia={handleAmendMedia}
          handleAmendMediaFolder={handleAmendMediaFolder}
          handleArchiveMediaFolder={handleArchiveMediaFolder}
          userHasWritePermission={userHasWritePermission}
        />
        <Navigation
          requestExport={(fileType: string, extension: string) => exportAssembly(fileType, extension)}
          handleSaveClicked={segments ? saveCurrentSegments : null}
          hasUnsavedChanges={unsavedChanges}
          isSaving={loadingSaveAssembly || !saveEnabled}
          saveEnabled={saveEnabled}
          isReadyForExport={true}
          canOpenShareModal={!!assemblyProjectData && assemblyProjectData?.getAssemblyProject?.is_owner}
          name={assemblyProjectData?.getAssemblyProject?.name || 'Loading Project...'}
          saveName={
            assemblyProjectData?.getAssemblyProject?.name
              ? (name: string) => {
                  pushProgress('rename');
                  renameProject({ variables: { name, projectId: projectid } });
                }
              : null
          }
          projectid={projectid}
          mediaInfo={mediaInfo}
          handleAmendAssemblyProject={handleAmendAssemblyProject}
          visitors={otherVisitorsFilter}
        />
        <Container fluid id="canvas">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              {bucket.col1.media && (
                <div className="bucket">
                  <Bucket
                    onCloseBucket={() => onCloseBucket(1)}
                    // isShowButtonClose={!!bucket.col1.media && !!bucket.col2.media}
                    isShowButtonClose={true}
                    editorState={bucket.col1.transcriptState}
                    currentTimePlaying={bucket.col1.currentTimePlaying}
                    current={bucket.col1.media}
                    onBackHistory={() => handleBackHistoryBucket(1)}
                  >
                    {bucket.col1.media && (
                      <Transcript
                        identifier="col1"
                        key={bucket.col1.media.id}
                        media={bucket.col1.media}
                        handleInsertClick={handleInsertClick}
                        handleAppendClick={handleAppendClick}
                        handleRemoveClick={handleRemoveClick}
                        editorState={bucket.col1.transcriptState}
                        onChangeCursor={() =>
                          setBucket(curBucket => ({
                            col1: {
                              ...curBucket.col1,
                              canBeReplaced: true,
                            },
                            col2: {
                              ...curBucket.col2,
                              canBeReplaced: false,
                            },
                          }))
                        }
                        setEditorState={editorState =>
                          setBucket(curBucket => ({
                            ...curBucket,
                            col1: { ...curBucket.col1, transcriptState: editorState },
                          }))
                        }
                        onChangeTimePlaying={timeInSeconds =>
                          setBucket(curBucket => ({
                            ...curBucket,
                            col1: { ...curBucket.col1, currentTimePlaying: timeInSeconds },
                          }))
                        }
                        readOnly={assemblyProjectData?.getAssemblyProject.my_privilege !== 'EDIT'}
                        mediaTimecode={bucket.col1.media.startTimeCode}
                      />
                    )}
                    {loadingAssemblyMedia && (
                      <div className="loading-transcript">
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} /> Grabbing the
                        transcript
                      </div>
                    )}
                  </Bucket>
                </div>
              )}

              <div className="col-placeholder"></div>

              {bucket.col2.media && !isNarrowView() && (
                <div className="bucket">
                  <Bucket
                    onCloseBucket={() => onCloseBucket(2)}
                    // isShowButtonClose={!!bucket.col1.media && !!bucket.col2.media}
                    isShowButtonClose={true}
                    editorState={bucket.col2.transcriptState}
                    currentTimePlaying={bucket.col2.currentTimePlaying}
                    current={bucket.col2.media}
                    onBackHistory={() => handleBackHistoryBucket(2)}
                  >
                    <Transcript
                      identifier="col2"
                      key={bucket.col2.media.id}
                      media={bucket.col2.media}
                      handleInsertClick={handleInsertClick}
                      handleAppendClick={handleAppendClick}
                      handleRemoveClick={handleRemoveClick}
                      editorState={bucket.col2.transcriptState}
                      onChangeCursor={() =>
                        setBucket(curBucket => ({
                          col1: {
                            ...curBucket.col1,
                            canBeReplaced: false,
                          },
                          col2: {
                            ...curBucket.col2,
                            canBeReplaced: true,
                          },
                        }))
                      }
                      setEditorState={editorState =>
                        setBucket(curBucket => ({
                          ...curBucket,
                          col2: { ...curBucket.col2, transcriptState: editorState },
                        }))
                      }
                      onChangeTimePlaying={timeInSeconds =>
                        setBucket(curBucket => ({
                          ...curBucket,
                          col2: { ...curBucket.col2, currentTimePlaying: timeInSeconds },
                        }))
                      }
                      readOnly={assemblyProjectData?.getAssemblyProject.my_privilege !== 'EDIT'}
                      mediaTimecode={bucket.col2.media.startTimeCode}
                    />
                    {loadingAssemblyMedia && (
                      <div className="loading-transcript">
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} /> Grabbing the
                        transcript
                      </div>
                    )}
                  </Bucket>
                </div>
              )}
            </div>

            <div className="col-placeholder"></div>

            <React.Fragment>
              {segmentsData && (
                <Assembly
                  handleCreateBlankSegment={handleCreateBlankSegment}
                  handleEditBlankSegment={handleEditBlankSegment}
                  handleCreateChapter={handleCreateChapter}
                  handleRenameChapter={handleRenameChapter}
                  handleRemoveChapter={handleRemoveChapter}
                  handleReorderChapter={handleReorderChapter}
                  hasBucketFocused={getHasBucketFocused()}
                  segments={segments}
                  handleRemoveSegment={handleRemoveSegment}
                  handleReorderSegment={handleReorderSegment}
                  handleReorderSegments={handleReorderSegments}
                  currentlyPlayingVideo={currentlyPlayingVideo}
                  setCurrentTimePlaying={setCurrentTimePlaying}
                  setCurrentlyPlayingVideo={setCurrentlyPlayingVideo}
                  readOnly={assemblyProjectData?.getAssemblyProject.my_privilege !== 'EDIT'}
                  handleSelectedImportedMedia={updateRoute}
                  visitors={otherVisitorsFilterExcludeSelf}
                  areColumnsOpen={!!(bucket.col1.media || bucket.col2.media) || getMediaParams().some(url => url)}
                />
              )}
            </React.Fragment>
          </div>
        </Container>

        <ModalConfirmfrom
          isOpen={isOpenModalConfirmDeleteMedia}
          toggle={() => setIsOpenModalConfirmDeleteMedia(!isOpenModalConfirmDeleteMedia)}
          cancel={() => setIsOpenModalConfirmDeleteMedia(false)}
          confirm={() => deleteAllSegmetsAndMeida()}
          title="Delete Media"
          message="Are you sure you want to remove this media from the bin? This will delete any selections from the timeline and any associated notes, and cannot be undone"
        />

        <ModalConfirmfrom
          isOpen={isOpenModalConfirmDeleteFolder}
          toggle={() => setIsOpenModalConfirmDeleteFolder(!isOpenModalConfirmDeleteFolder)}
          cancel={() => setIsOpenModalConfirmDeleteFolder(false)}
          confirm={() => deleteAllSegmetsAndMeidaInFolder()}
          title="Delete Folder"
          message="Are you sure you want to remove this folder from the bin? This will delete any selections from the timeline and any associated notes, and cannot be undone"
        />

        <ModalConfirmfrom
          isOpen={isOpenModalConfirmDeleteChapter}
          toggle={() => setIsOpenModalConfirmDeleteChapter(prev => !prev)}
          title="Delete Chapter"
          message="Are you sure you want to delete this chapter and all the media selections it contains"
          renderFooter={() => (
            <React.Fragment>
              <Button className="btn-ok" onClick={() => handleRemoveChapterAndSelections()}>
                <small>Delete chapter and {currentRemoveChapter.current.length - 1} selections</small>
              </Button>
              <Button className="btn-ok" onClick={() => handleRemoveOnlyChapter()}>
                <small>Delete chapter only</small>
              </Button>
              <Button className="btn-cancel" onClick={() => setIsOpenModalConfirmDeleteChapter(prev => !prev)}>
                <small>Cancel</small>
              </Button>
            </React.Fragment>
          )}
        />
      </div>
    </Fragment>
  );
};
export default Editor;
