import React from 'react';

type ChapterSVGProps = {
  color?: string;
  width?: number;
  height?: number;
};

export default function ChapterSVG(props: ChapterSVGProps) {
  let { color, width, height } = props;
  color = color ? color : '#fff';
  width = width ? width : 24;
  height = height ? height : 24;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <rect width={width} height={height} fill="white" fillOpacity="0.01" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4ZM8 8H16C16.5523 8 17 8.44772 17 9C17 9.55228 16.5523 10 16 10H8C7.44772 10 7 9.55228 7 9C7 8.44772 7.44772 8 8 8ZM16 11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11ZM8 14H11C11.5523 14 12 14.4477 12 15C12 15.5523 11.5523 16 11 16H8C7.44772 16 7 15.5523 7 15C7 14.4477 7.44772 14 8 14Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
