import React from "react";
import { createInlineStyleButton } from "draft-js-buttons";

const styleDefault = createInlineStyleButton({
  children: (
    <svg
      width="83%"
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="bookmark"
      className="svg-inline--fa fa-bookmark fa-w-12"
      role="img" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
    >
      <path d="M3.79409 0.85195V5.88295H11.0271V0.85195H3.79409ZM3.79409 0.0659497H11.0271C11.1306 0.0650156 11.2332 0.0847084 11.329 0.123878C11.4248 0.163047 11.5118 0.220908 11.585 0.294082C11.6581 0.367256 11.716 0.454276 11.7552 0.55006C11.7943 0.645843 11.814 0.748471 11.8131 0.85195V5.88295C11.814 5.98643 11.7943 6.08906 11.7552 6.18484C11.716 6.28062 11.6581 6.36764 11.585 6.44082C11.5118 6.51399 11.4248 6.57185 11.329 6.61102C11.2332 6.65019 11.1306 6.66988 11.0271 6.66895H3.79409C3.69061 6.66988 3.58798 6.65019 3.4922 6.61102C3.39641 6.57185 3.30939 6.51399 3.23622 6.44082C3.16305 6.36764 3.10518 6.28062 3.06601 6.18484C3.02685 6.08906 3.00715 5.98643 3.00809 5.88295V0.85195C3.00715 0.748471 3.02685 0.645843 3.06601 0.55006C3.10518 0.454276 3.16305 0.367256 3.23622 0.294082C3.30939 0.220908 3.39641 0.163047 3.4922 0.123878C3.58798 0.0847084 3.69061 0.0650156 3.79409 0.0659497Z" fill="currentColor"/>
      <path d="M7.78209 7.21997H6.99609V12.566H7.78209V7.21997Z" fill="currentColor"/>
      <path d="M7.21418 12.803L4.76318 10.113L5.34518 9.578L7.38919 11.811L9.35418 9.578L9.95418 10.097L7.58019 12.786C7.55776 12.8116 7.53038 12.8324 7.49968 12.8472C7.46899 12.8619 7.43563 12.8703 7.40161 12.8718C7.36759 12.8733 7.33362 12.8679 7.30175 12.8559C7.26988 12.8439 7.24077 12.8256 7.21618 12.802L7.21418 12.803Z" fill="currentColor"/>
      <path d="M0.650043 8.40295H3.32104C3.4877 8.40348 3.64739 8.46992 3.76523 8.58777C3.88308 8.70561 3.94952 8.86529 3.95004 9.03195V13.432C3.94952 13.5986 3.88308 13.7583 3.76523 13.8761C3.64739 13.994 3.4877 14.0604 3.32104 14.061H0.648045C0.481386 14.0604 0.321704 13.994 0.203857 13.8761C0.0860112 13.7583 0.0195704 13.5986 0.019043 13.432V9.03195C0.0195723 8.86495 0.0862888 8.70497 0.204567 8.58706C0.322845 8.46916 0.483038 8.40295 0.650043 8.40295Z" fill="currentColor"/>
      <path d="M11.3422 8.40295H14.0142C14.1808 8.40348 14.3405 8.46992 14.4584 8.58777C14.5762 8.70561 14.6427 8.86529 14.6432 9.03195V13.432C14.6427 13.5986 14.5762 13.7583 14.4584 13.8761C14.3405 13.994 14.1808 14.0604 14.0142 14.061H11.3402C11.1735 14.0604 11.0138 13.994 10.896 13.8761C10.7781 13.7583 10.7117 13.5986 10.7112 13.432V9.03195C10.7117 8.86495 10.7784 8.70497 10.8967 8.58706C11.015 8.46916 11.1752 8.40295 11.3422 8.40295Z" fill="currentColor"/>
    </svg>
  )
});

export default function insertBtn(_) {
  return styleDefault
}
