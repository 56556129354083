import React, { useMemo, useState, useEffect } from 'react';
// import ResizeObserver from 'resize-observer-polyfill';
import moment from 'moment';
import { EditorBlock, ContentBlock, ContentState, Editor, EditorState } from 'draft-js';
import Speaker from '../popover/Speaker';
import SpeakerPopover from '../popover/SpeakerPopover';
import Annotation from '../popover/Annotation';
import AnnotationPopover from '../popover/AnnotationPopover';
import SPEAKER_COLORS from '../../common/constants/speaker-colors.constant';

type blockProps = {
  blocksRelevantFilter: Array<string>;
  isSubtitled: boolean;
  isReadOnly: boolean;
  isTextRightToLeft: boolean;
  lockEditor: () => void;
  unlockEditor: () => void;
  updateAllMatchingAutoSpeaker: (speaker: speaker | null, key: string, autoSpeaker: boolean) => EditorState;
  onUpdateBlockData: (data: Object | null, key: string, editorState?: EditorState) => EditorState;
  onChangeTimePlaying: (timeInSeconds: number | null) => void;
  mediaTimecode?: string;
  identifier: string;
};

type TranscriptRowProps = {
  updatedSpeakerList: Array<speaker>;
  blockProps: blockProps;
  block: ContentBlock;
  contentState: ContentState;
};

export default function TranscriptRow(props: TranscriptRowProps) {
  const [isCurrentlyPlayedRow, setIsCurrentlyPlayedRow] = useState(false);
  const [entireRowHighlighted, setEntireRowHighlighted] = useState(false);
  const [speakerPopoverOpen, setSpeakerPopoverOpen] = useState(false);
  const [annotationPopoverOpen, setAnnotationPopoverOpen] = useState(false);
  const [lockAddSpeaker, setLockAddSpeaker] = useState(false);
  const [newSpeaker, setNewSpeaker] = useState<speaker>({ name: '' });
  const [annotationRect, setAnnotationRect] = useState<{ [key: string]: number }>({ x: 0, width: 100 });
  const [isAnnotationValueChanged, setIsAnnotationValueChanged] = useState(false);
  const [annotationValue, setAnnotationValue] = useState(props.block.getData().get('annotation') || '');
  const [autoSpeaker, setAutoSpeaker] = useState(props.block.getData().get('autoSpeaker'));
  const [startTime, setStartTime] = useState(() => {
    let startEntityKey;
    let counterStartChar = 0;

    do {
      startEntityKey = props.block.getEntityAt(counterStartChar++);
    } while (!startEntityKey && counterStartChar < props.block.getLength());

    return startEntityKey
      ? props.contentState.getEntity(startEntityKey).getData().startTime
      : props.block.getData().get('startTime');
  });

  const [endTime, setEndTime] = useState(() => {
    let endEntityKey;
    let counterEndChar = props.block.getLength();

    do {
      endEntityKey = props.block.getEntityAt(counterEndChar--);
    } while (!endEntityKey && counterEndChar > 0);

    return endEntityKey
      ? props.contentState.getEntity(endEntityKey).getData().endTime
      : props.block.getData().get('endTime');
  });

  // useEffect(() => {
  //   const el = document.getElementById('bucket-col-2');
  //   const resizeObserver = new ResizeObserver((entries) => {
  //     for (const entry of entries) {
  //       const { contentRect, target } = entry;
  //       if(contentRect.width >= 70) {
  //         const rect = target.getBoundingClientRect();
  //         setAnnotationRect({x: rect.x, width: contentRect.width + 20});
  //       } else {
  //         setAnnotationRect(prevState => ({...prevState, x: 0, width: 100}));
  //       }
  //     }
  //   });

  //   if(el) resizeObserver.observe(el);

  //   return () => {
  //     if(el) resizeObserver.disconnect();
  //   };
  // }, []);

  const {
    updatedSpeakerList,
    blockProps: {
      blocksRelevantFilter,
      isSubtitled,
      isReadOnly,
      isTextRightToLeft,
      lockEditor,
      unlockEditor,
      onChangeTimePlaying,
    },
    block,
  } = props;
  // const {
  //   isCurrentlyPlayedRow,
  //   entireRowHighlighted,
  //   speakerPopoverOpen,
  //   newSpeaker,
  //   annotationPopoverOpen,
  //   annotationValue,
  //   lockAddSpeaker
  // } = this.state;

  function playTime(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.stopPropagation();
    onChangeTimePlaying(startTime);
  }

  function getSpeakerCssStyle(speaker: speaker | null): React.CSSProperties | undefined {
    const colorObject = SPEAKER_COLORS.find(function(speakerColorItem) {
      if (speaker) {
        return speakerColorItem.color === speaker.color;
      }
      return false;
    });
    if (colorObject) {
      return {
        // backgroundColor: colorObject.color,
        color: colorObject.color,
        fontSize: 'inherit',
        fontWeight: 'bold',
      };
    }
  }

  function secondsToTimeFilter() {
    const padTime: (time: number) => number | String = t => (t < 10 ? '0' + t : t);

    return function(_seconds: number) {
      if (isNaN(Number(_seconds)) || _seconds < 0) return '00:00';

      const hours = Math.floor(_seconds / 3600),
        minutes = Math.floor((_seconds % 3600) / 60),
        seconds = Math.floor(_seconds % 60);

      if (hours > 0) return hours + ':' + padTime(minutes) + ':' + padTime(seconds);
      else return padTime(minutes) + ':' + padTime(seconds);
    };
  }

  function getFormattedTimestamp(time: any) {
    const timeCodeOffset = props.blockProps.mediaTimecode?.substring(0, 8) || 0;
    const timeCodeInSeconds = moment.duration(timeCodeOffset).asMilliseconds() / 1000;

    return secondsToTimeFilter()(timeCodeInSeconds + parseFloat(time));
  }

  function hasAnnotation() {
    return !!props.block.getData().get('annotation');
  }

  function hasBookmark() {
    return !!props.block.getData().get('bookmark');
  }

  function getSpeaker(): speaker | null {
    if (props.block.getData().get('speaker')) {
      const speaker = JSON.parse(props.block.getData().get('speaker'));
      return speaker;
      // return props.updatedSpeakerList.find(
      //   (speakerItem) => speakerItem.id === speaker.id
      // );
    }

    return null;
  }

  function focusPopupTextAreaAndKeepScrollPosition(idDOM: string) {
    setTimeout(() => {
      const popoverDOM: HTMLElement | null = document.getElementById(idDOM);
      if (popoverDOM) {
        popoverDOM.focus();
        if (/annotation-*/.test(idDOM)) (popoverDOM as HTMLInputElement).selectionStart = annotationValue.length;
      }
    }, 0);
  }

  function toggleSpeakerPopover(blockKey: string) {
    const [x, y] = [window.scrollX, window.scrollY];
    const shouldFocus = speakerPopoverOpen && !props.blockProps.isReadOnly;
    setSpeakerPopoverOpen(prevSate => !prevSate);
    if (shouldFocus) {
      window.scrollTo(x, y);
      focusPopupTextAreaAndKeepScrollPosition(`speaker-${blockKey}`);
    }
  }

  function toggleAnnotationPopover(blockKey: string) {
    const [x, y] = [window.scrollX, window.scrollY];
    const shouldFocus = !annotationPopoverOpen && !props.blockProps.isReadOnly;
    setAnnotationPopoverOpen(prevState => !prevState);
    if (shouldFocus) {
      window.scrollTo(x, y);
      focusPopupTextAreaAndKeepScrollPosition(`annotation-${blockKey}`);
    }
  }

  function updateBlockData(data: Object | null) {
    // return props.blockProps.onUpdateBlockData(data, props.block.getKey());
  }

  function setTranscriptionSpeaker(speaker: speaker | null) {
    let updatedEditorState;
    if (autoSpeaker) {
      updatedEditorState = props.blockProps.updateAllMatchingAutoSpeaker(speaker, props.block.getKey(), autoSpeaker);
    } else {
      updatedEditorState = updateBlockData({
        ['speaker']: speaker ? JSON.stringify(speaker) : null,
      });
    }

    // this.props.blockProps.onChangeps4State(updatedEditorState, () => {
    //   // this.setState({ speakerPopoverOpen: false });
    //   this.props.blockProps.onSaveTranscript(() => {
    //     this.setState({ speakerPopoverOpen: false, newSpeaker: { name: "" }, lockAddSpeaker: false });
    //   });
    // });
  }

  function addSpeaker() {
    // const speakerToAdd = {
    //   ...this.state.newSpeaker,
    //   name: this.state.newSpeaker.name.replace(/\s+/g, " ").trim(),
    // };
    // if (!speakerToAdd.name) return;
    // this.setState({ lockAddSpeaker: true });
    // speakerToAdd.color = this.getNoRepeatedColor();
    // this.props.blockProps
    //   .onAddMediaSpeaker(speakerToAdd)
    //   .then((createdSpeaker) => {
    //     this.setTranscriptionSpeaker(createdSpeaker);
    //   });
  }

  function onChangeSpeaker(index: number, value: string) {
    // const speakerToUpdate = {
    //   ...this.props.updatedSpeakerList[index],
    //   name: value,
    // };
    // this.props.updateSpeakerItem(speakerToUpdate);
    // this.delayedSpeakerUpdateCallback(speakerToUpdate);
  }

  function correctSpeaker(index: number, value: string) {
    value = value.replace(/\s+/g, ' ').trim();
    onChangeSpeaker(index, value);
  }

  function onChangeNewSpeaker(event: React.ChangeEvent<HTMLInputElement>) {
    setNewSpeaker({ name: event.target.value });
  }

  function handleNewSpeakerKeyDown(e: React.KeyboardEvent<HTMLElement>) {
    if (e.key === 'Enter') {
      addSpeaker();
    }
  }

  function onChangeAnnotation(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setAnnotationValue(event.target.value);
    setIsAnnotationValueChanged(true);
  }

  function saveAnnotation() {
    if (props.blockProps.isReadOnly) return;
    if (!isAnnotationValueChanged) return;

    const updatedEditorState = updateBlockData({
      ['annotation']: annotationValue,
    });

    // this.props.blockProps.onChangeps4State(updatedEditorState, () => {
    //   // this.setState({ annotationPopoverOpen: false });
    //   this.setState({ isAnnotationValueChanged: false });
    //   this.props.blockProps.onSaveTranscript();
    // });
  }

  function onAnnotationEnterPress(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.keyCode == 13 && event.shiftKey == false) {
      event.preventDefault();
      (event.target as HTMLTextAreaElement).blur();
      saveAnnotation();
    }
  }

  function cancelAnnotationChange() {
    setAnnotationValue(props.block.getData().get('annotation') || '');
    setIsAnnotationValueChanged(false);
  }

  const rowStyle = entireRowHighlighted ? { color: '#000' } : {};
  const formattedStartTime = getFormattedTimestamp(startTime);
  const formattedEndTime = getFormattedTimestamp(endTime);
  const isInvisibleBlock = !blocksRelevantFilter?.includes(block.getKey());
  let display = 'block';

  if (blocksRelevantFilter?.length && isInvisibleBlock) {
    display = 'none';
  }
  const speaker = getSpeaker()?.name || null;
  return (
    <div className={`transcription-row ${isCurrentlyPlayedRow ? 'playing' : ''}`} style={{ display }}>
      <div className="transcription-data-container">
        <div contentEditable={false} className="transcription-top-bar">
          <span className="transcription-time" onClick={playTime}>
            {formattedStartTime} - {formattedEndTime}
          </span>
          {speaker && <span className="transcription-speaker">{speaker}</span>}
          {/* {!isSubtitled && (
            <Speaker
              readOnly={isReadOnly}
              blockKey={block.getKey()}
              currentSpeaker={getSpeaker()}
              isOpen={speakerPopoverOpen}
              toggleSpeakerPopover={toggleSpeakerPopover}
              getSpeakerCssStyle={getSpeakerCssStyle}
              controlEditor={{ lockEditor, unlockEditor }}
            >
              {speakerPopoverOpen && (
                <SpeakerPopover
                  addSpeaker={addSpeaker}
                  handleNewSpeakerKeyDown={handleNewSpeakerKeyDown}
                  onChangeNewSpeaker={onChangeNewSpeaker}
                  newSpeaker={newSpeaker}
                  speaker={getSpeaker()}
                  setTranscriptionSpeaker={setTranscriptionSpeaker}
                  updatedSpeakerList={props.updatedSpeakerList ?? []}
                  onChangeSpeaker={onChangeSpeaker}
                  correctSpeaker={correctSpeaker}
                  blockKey={block.getKey()}
                  disabled={lockAddSpeaker}
                />
              )}
            </Speaker>
          )} */}

          {hasAnnotation() && (
            <Annotation
              annotationRect={annotationRect}
              readOnly={isReadOnly}
              annotationPopoverOpen={annotationPopoverOpen && display === 'block'}
              blockKey={block.getKey()}
              hasAnnotation={hasAnnotation()}
              toggleAnnotationPopover={toggleAnnotationPopover}
              suffix={props.blockProps.identifier}
            >
              {({ scheduleUpdate }) =>
                annotationPopoverOpen && (
                  <AnnotationPopover
                    annotationRect={annotationRect}
                    scheduleUpdate={scheduleUpdate}
                    isInvisibleBlock={isInvisibleBlock}
                    blocksRelevantFilter={blocksRelevantFilter}
                    onAnnotationEnterPress={onAnnotationEnterPress}
                    onChangeAnnotation={onChangeAnnotation}
                    annotationValue={annotationValue}
                    annotationAuthor="Transcriber"
                    isReadOnly={true}
                    cancelAnnotationChange={cancelAnnotationChange}
                    saveAnnotation={saveAnnotation}
                    blockKey={block.getKey()}
                    controlEditor={{ lockEditor, unlockEditor }}
                  />
                )
              }
            </Annotation>
          )}
        </div>
      </div>
      <div style={rowStyle}>
        <EditorBlock {...props} />
      </div>
    </div>
  );
}
