import React from "react";
import getInsertButton from "./insertBtn";
import getAppendButton from "./appendBtn";
import getRemoveButton from "./removeBtn";

import { myActions } from "./plugins";

const mapComponent = {
  [myActions.INSERT]: getInsertButton,
  [myActions.APPEND]: getAppendButton, 
  [myActions.REMOVE]: getRemoveButton, 
};

const getInlineToolbarComps = ({ style, action, visibleWhenInactive, props, onClick }) => {
  const editorState = props.getEditorState();
  const selection = editorState.getSelection();
  //const inlineStyle = editorState.getCurrentInlineStyle();
  const startStyle = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getInlineStyleAt(selection.getStartOffset());
  const endStyle = editorState.getCurrentContent().getBlockForKey(selection.getEndKey()).getInlineStyleAt(selection.getEndOffset() > 1 ? selection.getEndOffset()-1 : 0);
  //const isActive = inlineStyle.has(style)
  const isActive = startStyle.has(style) && endStyle.has(style);
  const DynamicComponent =
    visibleWhenInactive || isActive ? mapComponent[action]() : null;

  return (
    (DynamicComponent && (
      <span key={`DynamicComponent-${action}`} onClick={() => onClick()}>
        <DynamicComponent {...props} />
      </span>
    )) ||
    null
  );
};

export { getInlineToolbarComps };
