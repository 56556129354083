import { reorder } from 'react-reorder';

const resolveToNewState = (incomingSegments: Segment[], actionsToApply: SegmentReducerAction[]) => {
  let state = incomingSegments;
  // If we have an INSERT action, insert at the same place (or max if place doesn't exist anymore, append)
  // If we have an APPEND action, just apply it (insert at the end)
  // If we have a REMOVE action, ignore it. The other user may have made critical changes
  // If we have REORDER action, we'll try and match the original reorder to similar segments. If we can't find it, then do nothing as it's been deleted or split. If more than one matches, we'll pick the closest one in index position
  // If we have an AMEND action, we'll try and amend one in the same position if it matches in other ways, or if not, amend it wherever we can find it. If more than one matches, we'll pick the closest one in index position
  actionsToApply.forEach(action => {
    const finder = (segment: Segment) =>
      segment?.imported_media?.id === action.segment?.imported_media?.id &&
      (segment.start === action.segment?.start || segment.end === action.segment?.end);
    const matcher = (acc: number[], current: boolean, index: number) => (current ? acc.concat(index) : acc);
    const found = state.map(finder);
    const matchingIndexes = found.reduce(matcher, []);
    const destinationIndex =
      typeof action.nextIndex === 'number' && action.nextIndex < state.length ? action.nextIndex : state.length - 1;
    switch (action.type) {
      case 'INSERT':
        if (typeof action.index === 'number' && action.segment && action.index < state.length) {
          state = [...state.slice(0, action.index), action.segment, ...state.slice(action.index)];
        } else if (action.segment) {
          state = state.concat(action.segment);
        }
        return;
      case 'APPEND':
        if (action.segment) state = state.concat(action.segment);
        return;
      case 'REORDER':
        if (matchingIndexes.length > 0) {
          //find closest one
          const diffIndexes = matchingIndexes
            .map(index => {
              return { index, distance: index - (action.previousIndex || 0) };
            })
            .sort((a, b) => a.distance - b.distance);
          if (diffIndexes[0].index !== destinationIndex) state = reorder(state, diffIndexes[0].index, destinationIndex);
        }
        return;
      case 'AMEND':
        if (typeof action.index === 'number' && action.segment && action.original) {
          const candidate = state[action.index];
          if (
            candidate?.imported_media?.id === action.original?.imported_media?.id &&
            (candidate.start === action.original.start || candidate.end === action.original.end)
          ) {
            // we have a match
            state = [...state.slice(0, action.index), action.segment, ...state.slice(action.index + 1)];
          } else {
            // let's go for a search for the closest item that matches exactly
            if (matchingIndexes.length > 0) {
              const diffIndexes = matchingIndexes
                .map(index => {
                  return { index, distance: index - (action.previousIndex || 0) };
                })
                .sort((a, b) => a.distance - b.distance);
              state = [
                ...state.slice(0, diffIndexes[0].index),
                action.segment,
                ...state.slice(diffIndexes[0].index + 1),
              ];
            }
          }
        }
        return;
    }
  });
  return state;
};

export { resolveToNewState };
