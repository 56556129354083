import React, { useRef, MutableRefObject} from 'react';
// import _ from 'lodash';
import { useDebouncedEffect } from '../../../common/custom-hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

type BoxFindOrReplaceProps = {
  active: boolean;
  textSearch: string | null; 
  onSearch: () => void;
  onRemoveSearch: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

// eslint-disable-next-line react/display-name
const BoxFindOrReplace = React.forwardRef<HTMLInputElement | null, BoxFindOrReplaceProps>((props: BoxFindOrReplaceProps, ref) => {
  useDebouncedEffect(onSearch, 500, [props.textSearch]);

  function handleKeypress(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      onSearch();
    }
  }

  function onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    props.onChange(event);
  }

  function onSearch() {
    props.onSearch();
  }

  function onRemove() {
    props.onRemoveSearch();
  }

  if (!props.active) return null;

  return (
    <div id="box-search-replace">
      <div className="input-search">
        <span onClick={onSearch} className="prefix-icon">
          <FontAwesomeIcon icon={faSearch} width={16} height={16} />
        </span>
        <input
          ref={ref}
          name="search"
          value={props.textSearch ?? ''}
          onChange={onChangeInput}
          onKeyPress={handleKeypress}
          placeholder="Search"
        />
        {props.textSearch && (
          <span onClick={onRemove} className="suffix-icon">
            <FontAwesomeIcon icon={faTimes} width={16} height={16} />
          </span>
        )}
      </div>
    </div>
  );
});

export default BoxFindOrReplace;
