import React, { useRef } from 'react';
import { Popover } from 'reactstrap';
import isMobile from 'ismobilejs';
import * as Popper from 'popper.js';

const modifiers = (annotationRect: any) => {
  return {
    //option in popperjs 1.x
    offset: {
      fn: function(data: any) {
        //15 is padding of element id="bucket-col-2"
        data.offsets.popper.left = annotationRect.x === 0 ? data.offsets.popper.left : annotationRect.x - (15/4);
        return data
      }
    },
    preventOverflow: {
      boundariesElement: 'viewport',
    },
  };
}

type AnnotationProps = {
  readOnly: boolean;
  hasAnnotation: boolean;
  annotationPopoverOpen: boolean;
  blockKey: string;
  suffix?: string;
  annotationRect: {[key: string]: number};
  children: (param: { scheduleUpdate: any }) => void;
  toggleAnnotationPopover: (blockKey: string) => void;
};

export default function Annotation(props: AnnotationProps) {
  const { readOnly, hasAnnotation, annotationPopoverOpen, blockKey, annotationRect, toggleAnnotationPopover, children } = props;
  const annotationRef = useRef(null);

  let annotationClass = `notes-icon fa fa-sticky-note${!hasAnnotation ? '-o' : ''} ${
    hasAnnotation || annotationPopoverOpen ? ' visible' : ''
  }`;

  if (readOnly) {
    annotationClass = `${hasAnnotation ? 'notes-icon fa fa-sticky-note visible' : ''}`;
  }

  const modifiersFinal = modifiers(annotationRect);

  return (
    <React.Fragment>
      <span ref={annotationRef} className="inner-toolbar inner-toolbar-right annotation-row">
        <span
          id={`Popover-${blockKey}${props.suffix && "-" + props.suffix || ""}`}
          className={annotationClass}
          title="Note"
          onClick={() => toggleAnnotationPopover(blockKey)}
        ></span>
        <Popover
          fade={false}
          modifiers={modifiersFinal as Popper.Modifiers}
          trigger="legacy"
          isOpen={annotationPopoverOpen}
          target={`Popover-${blockKey}${props.suffix && "-" + props.suffix || ""}`}
          // placement="right"
          onClick={(e) => {
            e.stopPropagation()
            toggleAnnotationPopover(blockKey)
          }}
        >
          {({ scheduleUpdate }) => children({ scheduleUpdate })}
        </Popover>
      </span>
    </React.Fragment>
  );
}
