import React from 'react';
import {
  CompositeDecorator, ContentBlock, SelectionState
} from "draft-js";

type SearchHighlightProps = {
  selectionState: SelectionState | null;
  blockKey: string;
  start: number;
  children: JSX.Element | null
}

const SearchHighlight = (props: SearchHighlightProps) => {
  const { selectionState, blockKey, start } = props
  if(selectionState && selectionState.getAnchorKey() === blockKey && start === selectionState.getAnchorOffset()) {
    return <span className="current-search-and-replace-highlight">{props.children}</span>
  }
  return <span className="search-and-replace-highlight">{props.children}</span>
};

export const findWithRegex = (regex: RegExp, contentBlock: ContentBlock | undefined, callback: Function) => {
  if(!regex || !contentBlock || !callback) return;

  const text = contentBlock.getText();
  let matchArr, start, end;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    end = start + matchArr[0].length;
    callback(start, end);
  }
};

export const generateDecorator = (highlightTerm: string, selectionState: SelectionState | null) => {
  const regex = new RegExp(normalizedStringRegex(highlightTerm), 'gmi');
  return new CompositeDecorator([
    {
      strategy: (contentBlock, callback) => {
        if (highlightTerm !== '') {
          findWithRegex(regex, contentBlock, callback);
        }
      },
      component: SearchHighlight,
      props: {
        selectionState: selectionState
      }
    },
  ])
};

function normalizedStringRegex(str: string) {
  return str.replace(/([^a-z0-9\s])/gi, '\\$1');
}
