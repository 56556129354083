import { Modifier, SelectionState, EditorState, ContentBlock } from 'draft-js';
import { myDraftStyle } from './Toolbar/plugins';

const clearAllStyles = (editorState: EditorState) => {
  const blocks = editorState.getCurrentContent().getBlocksAsArray();
  const newSelectionState = new SelectionState({
    anchorKey: blocks[0].getKey(),
    anchorOffset: 0,
    focusKey: blocks[blocks.length - 1].getKey(),
    focusOffset: blocks[blocks.length - 1].getText().length,
  });
  return Modifier.removeInlineStyle(editorState.getCurrentContent(), newSelectionState, myDraftStyle.HIGHLIGHT);
};

const setStylesFromSegments: (editorState: EditorState, segments: Segment[], id: string) => EditorState = (
  editorState,
  segments,
  id,
) => {
  for (const segment of segments) {
    if (segment?.imported_media?.id !== id) {
      continue;
    }
    const selectionToApplyStyle = new SelectionState(mapGenericToSelection(segment.selection, editorState));
    try {
      const contentState = Modifier.applyInlineStyle(
        editorState.getCurrentContent(),
        selectionToApplyStyle,
        myDraftStyle.HIGHLIGHT,
      );
      editorState = EditorState.push(editorState, contentState, 'change-inline-style');
    } catch {
      console.log(
        `[Assembly][ERROR] failed to set the style state from segment with text "${segment.text}". Editor rejected selection`,
      );
      console.log(selectionToApplyStyle.toJS());
    }
  }
  return editorState;
};

// these two functions are required because the focusKey and anchorKey can change next time we load the transcript
//TODO grow these selections to encompass any neighbour entity that has the same timestamp OR change MODIFY action to use selection instead of times
const mapSelectionToGeneric: (selection: SelectionState, editorState: EditorState) => any = (
  selection,
  editorState,
) => {
  const blocks = editorState.getCurrentContent().getBlocksAsArray();
  return {
    ...selection.toJS(),
    anchorOffset: selection.getStartOffset(),
    focusOffset: selection.getEndOffset(),
    anchorIndex: blocks.findIndex(block => block.getKey() === selection.getStartKey()),
    focusIndex: blocks.findIndex(block => block.getKey() === selection.getEndKey()),
  };
};

const mapGenericToSelection: (generic: any, editorState: EditorState) => SelectionState = (generic, editorState) => {
  const blocks = editorState.getCurrentContent().getBlocksAsArray();
  return {
    ...generic,
    anchorKey: blocks[generic.anchorIndex].getKey(),
    focusKey: blocks[generic.focusIndex].getKey(),
    isBackward: false,
    hasFocus: false,
  };
};

//includes test to deterine if b is a subset of a (b within a)
const compareSelections = (a: any, b: any) => {
  const result = {
    sameStart: a.anchorIndex === b.anchorIndex && a.anchorOffset === b.anchorOffset,
    sameEnd: a.focusIndex === b.focusIndex && a.focusOffset === b.focusOffset,
    subset:
      (a.anchorIndex < b.anchorIndex || (a.anchorIndex === b.anchorIndex && a.anchorOffset <= b.anchorOffset)) &&
      (a.focusIndex > b.focusIndex || (a.focusIndex === b.focusIndex && a.focusOffset >= b.focusOffset)),
    overlapStart:
      (a.anchorIndex < b.anchorIndex || (a.anchorIndex === b.anchorIndex && a.anchorOffset <= b.anchorOffset)) &&
      (a.focusIndex > b.anchorIndex || (a.focusIndex === b.anchorIndex && a.focusOffset >= b.anchorOffset)),
    overlapEnd:
      (a.anchorIndex < b.focusIndex || (a.anchorIndex === b.focusIndex && a.anchorOffset <= b.focusOffset)) &&
      (a.focusIndex > b.focusIndex || (a.focusIndex === b.focusIndex && a.focusOffset >= b.focusOffset)),
    identical: false,
    overlap: false,
  };
  result.identical = result.sameStart && result.sameEnd;
  result.overlap = result.overlapStart || result.overlapEnd;
  return result;
};

const getRangeFromSelection: (
  editorState: EditorState,
  overrideSelection: SelectionState | undefined,
) => SegmentRange | null = (editorState, overrideSelection) => {
  const selection = overrideSelection ? overrideSelection : editorState.getSelection();
  if (selection.isCollapsed()) {
    return null;
  }
  const content = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const startBlock = content.getBlockForKey(startKey);
  const endBlock = content.getBlockForKey(endKey);
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();
  let startEntity = startBlock.getEntityAt(startOffset);
  if (!startEntity) {
    startEntity = startBlock.getEntityAt(startOffset - 1);
  }
  let endEntity = endBlock.getEntityAt(endOffset);
  if (!endEntity) {
    endEntity = endBlock.getEntityAt(endOffset - 1);
  }

  if (startEntity && endEntity) {
    const startData = content.getEntity(startEntity).getData();
    const endData = content.getEntity(endEntity).getData();

    let key = startKey;
    let block: ContentBlock | undefined = content.getBlockForKey(key);

    if (!block) return null;

    let text = '';
    if (startKey === endKey) {
      text = block.getText().slice(startOffset, endOffset);
    } else {
      text = block.getText().slice(startOffset);
    }

    while (key !== endKey) {
      block = content.getBlockAfter(key);
      if (!block) return null;

      key = block.getKey();
      const textToAdd = block.getText();
      if (key === endKey) {
        text += '\n\n' + textToAdd.slice(0, endOffset);
      } else {
        text += '\n\n' + textToAdd;
      }
    }

    return { start: startData.startTime, end: endData.endTime, text };
  } else {
    return null;
  }
};

export {
  clearAllStyles,
  setStylesFromSegments,
  mapSelectionToGeneric,
  mapGenericToSelection,
  compareSelections,
  getRangeFromSelection,
};
