import React from "react";
import { createInlineStyleButton } from "draft-js-buttons";

const styleDefault = createInlineStyleButton({
  children: (
    <svg
      width="90%"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="bookmark"
      className="svg-inline--fa fa-bookmark fa-w-12"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
    >
      <path d="M13.2428 0.707107L12.5356 0L9.00011 3.53553L9.70722 4.24264L13.2428 0.707107Z" fill="currentColor"/>
      <path d="M12.5356 4.24263L13.2427 3.53552L9.70714 -1.16783e-05L9.00004 0.707095L12.5356 4.24263Z" fill="currentColor"/>
      <path d="M1.77504 5.78603V10.817H9.00805V5.78603H1.77504ZM1.77504 5.00003H9.00805C9.11153 4.9991 9.21415 5.01879 9.30993 5.05796C9.40572 5.09713 9.49274 5.15499 9.56592 5.22816C9.63909 5.30134 9.69695 5.38836 9.73612 5.48414C9.77529 5.57993 9.79498 5.68255 9.79404 5.78603V10.817C9.79498 10.9205 9.77529 11.0231 9.73612 11.1189C9.69695 11.2147 9.63909 11.3017 9.56592 11.3749C9.49274 11.4481 9.40572 11.5059 9.30993 11.5451C9.21415 11.5843 9.11153 11.604 9.00805 11.603H1.77504C1.67157 11.604 1.56894 11.5843 1.47315 11.5451C1.37737 11.5059 1.29035 11.4481 1.21718 11.3749C1.144 11.3017 1.08614 11.2147 1.04697 11.1189C1.0078 11.0231 0.988111 10.9205 0.989045 10.817V5.78603C0.988111 5.68255 1.0078 5.57993 1.04697 5.48414C1.08614 5.38836 1.144 5.30134 1.21718 5.22816C1.29035 5.15499 1.37737 5.09713 1.47315 5.05796C1.56894 5.01879 1.67157 4.9991 1.77504 5.00003V5.00003Z" fill="currentColor"/>
    </svg>
  )
});

export default function removeBtn(_) {
  return styleDefault
}
