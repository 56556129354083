import React, { useEffect } from 'react';
import { PopoverBody } from 'reactstrap';

type AnnotationPopoverProps = {
  scheduleUpdate: any;
  annotationValue: any;
  annotationAuthor?: string;
  isReadOnly: boolean;
  blockKey: string;
  isInvisibleBlock?: boolean;
  blocksRelevantFilter?: string[];
  annotationRect: {[key: string]: number};
  onAnnotationEnterPress: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onChangeAnnotation: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  cancelAnnotationChange: () => void;
  saveAnnotation: () => void;
  controlEditor?: {
    lockEditor: () => void;
    unlockEditor: () => void;
  };
};

export default function AnnotationPopover(props: AnnotationPopoverProps) {
  const {
    onAnnotationEnterPress,
    onChangeAnnotation,
    annotationValue,
    annotationAuthor,
    isReadOnly,
    cancelAnnotationChange,
    saveAnnotation,
    blockKey,
    controlEditor,
    annotationRect
  } = props;

  useEffect(() => {
    props.scheduleUpdate();
  }, [props.blocksRelevantFilter?.length, props.isInvisibleBlock]);

  const styles = {
    textarea: {
      border: 'none',
      overflow: 'hidden',
      outline: 'none',
      boxShadow: 'none',
      resize: 'none',
      height: '54px',
      width: annotationRect.width  + 'px'
    },
  };
  if (annotationValue.length > 40) styles.textarea.height = '80px';

  return (
    <PopoverBody>
      <div style={{width: annotationRect.width}} className="annotation">
        <div style={{ position: 'relative' }}>
          <textarea
            id={`annotation-${blockKey}`}
            onKeyDown={onAnnotationEnterPress}
            onChange={onChangeAnnotation}
            value={annotationValue}
            className="form-control no-focus"
            readOnly={isReadOnly}
            placeholder="Note"
            style={styles.textarea as React.CSSProperties}
            onBlur={() => {
              saveAnnotation();
              controlEditor && controlEditor.unlockEditor();
            }}
            onFocus={(e) => {
              controlEditor && controlEditor.lockEditor();
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          ></textarea>
          {(!isReadOnly && annotationValue) && (
            <span style={{ position: 'absolute', top: 16, right: 8, cursor: "pointer" }} onClick={cancelAnnotationChange}>
              <span className="fa fa-times-circle"></span>
            </span>
          )}
        </div>
      </div>
    </PopoverBody>
  );
}
