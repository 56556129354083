import React from "react";
import { createInlineStyleButton } from "draft-js-buttons";

const styleDefault = createInlineStyleButton({
  children: (
    <svg
      width="84%"
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="bookmark"
      className="svg-inline--fa fa-bookmark fa-w-12"
      role="img" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
    >
      <path d="M4.23305 1.324V6.355H11.4661V1.324H4.23305ZM4.23305 0.537996H11.4661C11.5695 0.537062 11.6722 0.556754 11.7679 0.595924C11.8637 0.635093 11.9507 0.692954 12.0239 0.766128C12.0971 0.839302 12.155 0.926321 12.1941 1.0221C12.2333 1.11789 12.253 1.22052 12.2521 1.324V6.355C12.253 6.45847 12.2333 6.5611 12.1941 6.65689C12.155 6.75267 12.0971 6.83969 12.0239 6.91286C11.9507 6.98604 11.8637 7.0439 11.7679 7.08307C11.6722 7.12224 11.5695 7.14193 11.4661 7.141H4.23305C4.12957 7.14193 4.02694 7.12224 3.93116 7.08307C3.83538 7.0439 3.74836 6.98604 3.67518 6.91286C3.60201 6.83969 3.54415 6.75267 3.50498 6.65689C3.46581 6.5611 3.44612 6.45847 3.44705 6.355V1.324C3.44612 1.22052 3.46581 1.11789 3.50498 1.0221C3.54415 0.926321 3.60201 0.839302 3.67518 0.766128C3.74836 0.692954 3.83538 0.635093 3.93116 0.595924C4.02694 0.556754 4.12957 0.537062 4.23305 0.537996Z" fill="currentColor"/>
      <path d="M12.253 7.69189H11.467V13.0379H12.253V7.69189Z" fill="currentColor"/>
      <path d="M11.6401 13.2119L9.23413 10.5859L9.81613 10.0509L11.8601 12.2839L13.8261 10.0509L14.4261 10.5699L12.1141 13.2119C12.0504 13.2734 11.9652 13.3078 11.8766 13.3078C11.788 13.3078 11.7029 13.2734 11.6391 13.2119H11.6401Z" fill="currentColor"/>
      <path d="M1.714 8.87097H7.84599C8.09585 8.87176 8.33525 8.97137 8.51192 9.14804C8.6886 9.32472 8.7882 9.56412 8.789 9.81397V13.588C8.7882 13.8378 8.6886 14.0772 8.51192 14.2539C8.33525 14.4306 8.09585 14.5302 7.84599 14.531H1.714C1.46414 14.5302 1.22474 14.4306 1.04807 14.2539C0.871393 14.0772 0.771787 13.8378 0.770996 13.588V9.81397C0.771787 9.56412 0.871393 9.32472 1.04807 9.14804C1.22474 8.97137 1.46414 8.87176 1.714 8.87097Z" fill="currentColor"/>
    </svg>
  )
});


export default function appendBtn(_) {
  return styleDefault;
}
