import createInlineToolbarPlugin from "draft-js-inline-toolbar-plugin";
import { buttonStyles, toolbarStyles } from "./theme";

const myDraftStyle: {[key: string]: string} = {
  HIGHLIGHT: "HIGHLIGHT",
};
const myActions = {
  INSERT: "INSERT",
  APPEND: "APPEND",
  REMOVE: "REMOVE"
};

const EditorStyles = {
  [myDraftStyle.HIGHLIGHT]: {
    backgroundColor: "rgba(200, 36, 127, 0.6)", // css property
  },
};

function getInlineToolbarComp() {
  const inlineToolbarPlugin = createInlineToolbarPlugin({
    theme: { buttonStyles, toolbarStyles },
  });
  
  const { InlineToolbar } = inlineToolbarPlugin;

  const plugins =  [inlineToolbarPlugin, { customStyleMap: { ...EditorStyles } }];

  return {
    plugins,
    InlineToolbar
  }
}

export { myDraftStyle, myActions, getInlineToolbarComp };
